<template>
  <div id="app" :class="classes">
    <BaseLayout>
      <router-view/>
    </BaseLayout>
  </div>
</template>
<script>
import BaseLayout from './components/layouts/BaseLayout'
import Helper from './assets/jsipclient/helper'

export default {
  name: 'App',
  components: {BaseLayout},
  data() {
    return {
      classes: [],
      agent: {}
    }
  },
  created() {
    this.initialPageLoadHandler()
  },
  updated() {
    this.initialPageNavigationHandler()
  },
  methods: {
    initialPageLoadHandler() {
      this.initialBrowserDetectHandler()
    },
    initialPageNavigationHandler() {
      if (this.agent.browser.name === 'IE') {
        this.iePageNavigationHandler()
      }
    },
    iePageNavigationHandler() {
      const navigationType = performance.getEntriesByType("navigation")[0].type
      switch (navigationType) {
        case 'navigate':
          // Do nothing
          break;
        case 'reload':
          window.location.replace(window.location.pathname + window.location.search + window.location.hash)
          break;
        case 'back_forward':
          // Do nothing
          break;
        case 'prerender':
          // Do nothing
          break;
      }
    },
    initialBrowserDetectHandler() {
      const agent = (new Helper)
      this.agent = agent
      this.addToClassArray(agent.deviceName)
      if (agent.isMobile) {
        this.addToClassArray('mobile')
      }
      this.addToClassArray(agent.browser.os)
      this.addToClassArray(agent.browser.name)
      this.addToClassArray(agent.browser.name + '-' + agent.browser.version)
    },
    addToClassArray(className) {
      className = className.toString().toLowerCase()
      if (!this.classes.includes(className)) {
        this.classes.push(className)
      }
    }
  }
}

</script>
<style lang="scss">
@import 'src/assets/scss/app.scss';
</style>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch
}

#app {
}

</style>