require('./bootstrap')

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VTooltip from 'v-tooltip'
import i18n from './i18n'
import 'core-js'
import 'regenerator-runtime/runtime'

//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap'

window.IpApi = require('./assets/jsipclient')

Vue.config.productionTip = false
Vue.use(router)
Vue.use(store)
Vue.use(VTooltip)

window.Apv = new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')

