import Vue from 'vue'
import Vuex from 'vuex'
import Common from './assets/jsipclient/common'

Vue.use(Vuex)

/****************************************************/
// 設定値クラス
/****************************************************/
class Config {
  constructor () {
    this.userId = null
    this.token = null
  }

  /**
   * 設定値を保存(LocalStorageへ)
   */
  save () {
    localStorage.setItem('config', JSON.stringify(this))
  }

  /**
   * LocalStorageから設定値を読み込み
   * @returns {Config}
   */
  static load () {
    const obj = new Config()

    const json = JSON.parse(localStorage.getItem('config'))
    if (json) {
      if (json.hasOwnProperty('userId')) {
        obj.userId = json.userId
      }
      if (json.hasOwnProperty('token')) {
        obj.token = json.token
      }
    }
    return obj
  }
}

const store = new Vuex.Store({
  state: {
    config: Config.load(),
  },
  mutations: {
    configUserId (state, payload) {
      state.config.userId = payload
      Common.saveConfig(state.config)
    },
    configToken (state, payload) {
      state.config.token = payload
      Common.saveConfig(state.config)
    },
  },
  actions: {
    /**
     * ログイン
     *
     * @param commit
     * @param {Credentials} payload
     * @returns {Promise<Credentials>}
     */
    async Login ({commit}, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const api = Common.createLoginApi()
          const result = await api.loginPostAsync(payload)

          // save APIToken
          commit('configToken', result.apiToken)
          commit('configUserId', result.user.userId)
          resolve(result)
        }
        catch (error) {
          reject(error)
        }
      })
    },

    /**
     * ログアウト
     * @param commit
     * @returns {Promise<void>}
     */
    async logout ({commit}) {
      // apiトークンとユーザーIDをstoreから削除usersUserIdTokenDelete

      const token = this.state.config.token
      const userId = this.state.config.userId
      return new Promise(async (resolve, reject) => {
        try {
          const api = Common.createUserApi(token)
          const result = await api.usersUserIdLogoutPostAsync(userId)

          // apiトークンとユーザーIDをstoreから削除
          commit('configToken', null)
          commit('configUserId', null)
          resolve(result)
        }
        catch (error) {
          reject(error)
        }
      })
    },
  },
  getters: {
    config: (state) => {
      return state.config
    },
  }
})

export default store
