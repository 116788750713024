/* Constants */

// App detail
export const app = Object.freeze({
  alias: 'default-app',
  brand: '健康情報管理システム HeaLo'
})


// API Configuration
export const apiConfig = Object.freeze({
  basePath: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate, pre-check=0, post-check=0',
    'Pragma': 'no-cache',
    'Clear-Site-Data': '*',
    'X-Client-Key': process.env.VUE_APP_API_CLIENT_KEY,
    'X-API-Token': '',
    'X-Language': 'ja-JP'

  },
})

// Default setup
export const defaults = Object.freeze({
  alias:{
    languageKey: 'user-language'
  },
  adminType: 0,
  adminTypes: {
    0: 'すべて',
    1: 'JTA管理者',
    2: '大会管理者',
    3: 'なし',
  },
  accountStatus: 0,
  accountStatuses: {
    0: 'すべて', // All(Default)
    1: '有効', // Enabled
    2: '停止', // Disabled
  },
  tournamentStatuses: [
    {
      id: 0,
      name: 'すべて'
    },
    {
      id: 3,
      name: '終了'
    },
    {
      id: 2,
      name: '期間中'
    },
    {
      id: 1,
      name: '近日開始'
    },
  ],
  roleId: 0,
  tournamentId: 0,
  healthStatus: 0,
  healthStatuses: {
    0: 'すべて', // All(Default)
    1: '入場拒否', // Rejection
    2: '異常あり', // Abnormality
    3: '未入力', // No Input
    4: '大会承認済', // Acceptation
    5: '異常なし', // Normality
  },
  enterStatus: {
    0:  'NO INPUT',
    1: 'GOOD',
    2: 'BAD',
    reasons: {
      0: {
        'button': 'enter-status-normal',
        'icon': 'fas fa-address-card', // All(Default)
        'value': 'すべて'
      },
      1: {
        'button': 'enter-status-rejected',
        'icon': 'fas fa-times-circle', // Admission denied
        'value': '入場拒否'
      },
      2: {
        'button': 'enter-status-abnormal',
        'icon': 'fas fa-user-times', // Abnormality
        'value': '異常あり'
      },
      3: {
        'button': 'enter-status-empty',
        'icon': 'fas fa-question-circle', // No Input
        'value': '未入力'
      },
      4: {
        'button': 'enter-status-approved',
        'icon': 'far fa-calendar-check', // Approved
        'value': '大会承認済'
      },
      5: {
        'button': 'enter-status-normal',
        'icon': 'fas fa-user-check', // No abnormality
        'value': '異常なし'
      },
    }
  },
  alert: 0,
  alerts: {
    0: 'すべて',
    1: '有り',
    2: '無し',
  },
})

// Datatable
export const datatable = Object.freeze({
  labels: {
    user_id: 'ユーザーID',
    userId: 'ユーザーID',
    userName: 'ユーザー名',
    user_name: 'ユーザー名',
    is_admin: '管理者',
    isAdmin: '管理者',
    password: 'パスワード',
    confirm_password: 'パスワード(確認)',
    confirmPassword: 'パスワード(確認)',
    os: 'OS',
    version: 'OSversion',
    device_model_name: '型番',
    deviceModelName: '型番',
    deviceModel: '型番',
  },
  columns: {
    users: [
      {
        key: 'user_id',
        label: 'ID',
        clickable: true
      },
      {
        key: 'username',
        label: 'ユーザー名',
        sortable: false
      },
      {
        key: 'is_admin',
        label: '権限'
      },
    ],

    record: [
      {
        key: 'date',
        label: '日付'
      },
      {
        key: 'healthStatus',
        label: 'ステータス'
      },
      {
        key: 'heatStrokeScore',
        label: '熱中症点数'
      },
      {
        key: 'date',
        label: '日付',
        clickable: true,
        tooltip: false,
        sortable: false,
        class: 'width-50'
      },
    ],
  }
})

// Sidebar menu
export const sidebar = Object.freeze({
  menu: [
    {
      name: 'Dashboard',
      link: 'Dashboard',
    }
  ]
})

// Gender
export const roles = Object.freeze({
  0: 'ゲスト',
  1: '管理者',
})

// Messages
export const messages = Object.freeze({
  'errors': {
    404: 'データが存在しません。',
  },
  'datatable': {
    'noData': 'データが存在しません。'
  },
})
