import {defaults, apiConfig} from '@/constants'
import moment from 'moment'

/**
 * Helper
 *
 */

/* Browser detection: START*/
export default class {

  /*
  |--------------------------------------------------------------------------
  | Cookies reader/writer framework with full unicode support
  |--------------------------------------------------------------------------
  */
  static cookie = {
    set: (name, value, days) => {
      let expires
      if (days) {
        let date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toGMTString()
      } else {
        expires = ''
      }
      document.cookie = name + '=' + value + expires + '; path=/'
    },
    get: (name) => {
      if (document.cookie.length > 0) {
        let cStart = document.cookie.indexOf(name + '=')
        if (cStart !== -1) {
          cStart = cStart + name.length + 1
          let cEnd = document.cookie.indexOf(';', cStart)
          if (cEnd === -1) {
            cEnd = document.cookie.length
          }
          return unescape(document.cookie.substring(cStart, cEnd))
        }
      }
      return ''
    },
    delete: (name) => {
      if (!name || !this.cookie.has(name)) {
        return
      }
      this.cookie.set(name, '', -1)
    },
    has: (name) => {
      return !!this.cookie.get(name)
    },
  }

  constructor() {
    this.browser = {}
    this.unsupportedBrowsers = {
      Chrome: 70,
      Firefox: 60,
      IE: 10,
      Edge: 15,
      Opera: 50,
      Safari: 12
    }

    this._detectBrowser()
  }

  /**
   * Checks if the current browser is Internet Explorer.
   *
   * @returns {Boolean}
   */
  get isIE() {
    return this.browser.name === 'IE'
  }

  /**
   * Checks if the current browser is Edge.
   *
   * @returns {Boolean}
   */
  get isEdge() {
    return this.browser.name === 'Edge'
  }

  /**
   * Checks if the current browser is from Microsoft (Edge
   * or Internet Explorer).
   *
   * @returns {Boolean}
   */
  get isMicrosoft() {
    return this.isIE || this.isEdge
  }

  /**
   * Checks if the current browser is Firefox.
   *
   * @returns {Boolean}
   */
  get isFirefox() {
    return this.browser.name === 'Firefox'
  }

  /**
   * Checks if the current browser is Chrome.
   *
   * @returns {Boolean}
   */
  get isChrome() {
    return this.browser.name === 'Chrome'
  }

  /**
   * Checks if the current browser is Safari.
   *
   * @returns {Boolean}
   */
  get isSafari() {
    return this.browser.name === 'Safari'
  }

  /**
   * Checks if the current browser is from an Android device.
   *
   * @returns {Boolean}
   */
  get isAndroid() {
    return /Android/i.test(navigator.userAgent)
  }

  /**
   * Checks if the current browser is from a BlackBerry device.
   *
   * @returns {Boolean}
   */
  get isBlackBerry() {
    return /BlackBerry/i.test(navigator.userAgent)
  }

  /**
   * Checks if the current browser is from a Windows Mobile device.
   *
   * @returns {Boolean}
   */
  get isWindowsMobile() {
    return /IEMobile/i.test(navigator.userAgent)
  }

  /**
   * Checks if the current browser is Mobile Safari.
   *
   * @returns {Boolean}
   */
  get isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent)
  }

  /**
   * Checks if the current browser is iPad Safari.
   *
   * @returns {Boolean}
   */
  get isIPad() {
    return /iPad/i.test(navigator.userAgent)
  }

  /**
   * Checks if the current browser is iPhone Safari.
   *
   * @returns {Boolean}
   */
  get isIPhone() {
    return /iPhone/i.test(navigator.userAgent)
  }

  /**
   * Checks if the current browser is iPod Safari.
   *
   * @returns {Boolean}
   */
  get isIPod() {
    return /iPod/i.test(navigator.userAgent)
  }

  /**
   * Checks if the current browser is a mobile browser.
   *
   * @returns {Boolean}
   */
  get isMobile() {
    return (
      this.isAndroid || this.isBlackBerry || this.isWindowsMobile || this.isIOS
    )
  }

  /**
   * Checks if the current browser is a mobile browser.
   *
   * @returns {Boolean}
   */
  get deviceName() {
    return this.isAndroid ? 'Android' :
      this.isBlackBerry ? 'Blackberry' :
        this.isWindowsMobile ? 'IEMobile' :
          this.isIPad ? 'iPad' :
            this.isIPhone ? 'iPhone' :
              this.isIPod ? 'iPod' :
                'others'


  }

  /**
   * Get API configuration object
   * @param apiToken
   * @returns {ApiConfig}
   */
  static apiConfig(apiToken = null) {
    // Get the language setting stored in the cookie. 'ja-JP' or 'en-US'
    const lang = this.xLanguage()
    apiConfig.headers['X-API-Token'] = apiToken
    apiConfig.headers['X-Language'] = lang

    return new IpApi.ApiConfig(
      apiConfig.basePath,
      apiConfig.headers
    )
  }

  /*
  |--------------------------------------------------------------------------
  | Get language settings from cookie
  |--------------------------------------------------------------------------
   * @param locale
   * @returns String
   */
  static xLanguage(locale) {
    if (!locale || typeof locale == 'undefined') {
      locale = this.getDefaultLocale()
    }
    if (locale === 'en') {
      return 'en-US'
    } else if (locale === 'ja') {
      return 'ja-JP'
    } else {
      return this.xLanguage(locale)
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get the default language settings from cookie
  |--------------------------------------------------------------------------
   * @returns String
   */
  static getDefaultLocale() {
    let locale = this.cookie.get(defaults.alias.languageKey)
    if (!locale) {
      locale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ja"
    }
    return locale
  }

  /*
  |--------------------------------------------------------------------------
  | Get formatted response for Datatable pagination
  |--------------------------------------------------------------------------
   * @param response
   * @returns Object
   */
  static formatResponse(response) {
    const result = response
    const paging = result.paging

    const current_page = paging.page
    const per_page = paging.pageParCount
    const from = per_page * (current_page - 1) + 1
    const last_page = paging.pageCount
    const to = per_page * current_page
    const total = paging.totalCount

    const sort = result.sort
    const order = result.order

    const data = response.items

    return {
      sort: sort,
      order: order,
      current_page: current_page,
      per_page: per_page ? per_page : datatable.default.perPage,
      from: from,
      to: to,
      last_page: last_page,
      total: total,
      data: data
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get a list of numbers with zero padding
  |--------------------------------------------------------------------------
   * @param start Starting number in integer
   * @param stop Ending number in integer
   * @param nDigits Number of digits with the zeros in integer
   * @param step Step size
   * @returns Array
  */
  static getRange(start, end, nDigits= 2, step = 1) {
    return [...Array(end + 1 - start).keys()]
      .filter(i => !(i % Math.round(step)))
      .map((v) => {
        return (new Array(nDigits + 1).join('0') + (start + v)).slice(-nDigits)
      })
  }

  /*
  |--------------------------------------------------------------------------
  | Get button html by health status Id
  |--------------------------------------------------------------------------
  | Status Id:
  | 0: All(Default) / すべて
  | 1: Rejection / 入場拒否
  | 2: Abnormality / 異常あり
  | 3: No Input / 未入力
  | 4: Acceptation / 大会承認済
  | 5: Normality / 異常なし
  */
  static showHealthStatus(healthStatus, extraCss = '', statusText = '') {

    if (healthStatus === null || typeof healthStatus == 'undefined') {
      return ''
    }

    let reasons = defaults.enterStatus.reasons
    if (isNaN(healthStatus)) {
      const getKeyByValue = (obj, value) =>
        Object.keys(obj).find(key => obj[key].value === value)
      healthStatus = getKeyByValue(reasons, healthStatus);
    }
    let reason = reasons[healthStatus]
    return '<span class="badge badge-status border-0 height-28 width-104 ' + reason.button + ' ' + extraCss + '">' +
      '<i class="' + reason.icon + '"></i>' + statusText +
      '</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Show tournament status
  |--------------------------------------------------------------------------
  | tournamentStatus:
  | 1 = Coming soon / 近日開始
  | 2 = During the duration / 期間中
  | 3 = Ended / 終了,
  */
  static showTournamentStatus(tournamentStatus, extraCss = '', statusText = '') {
    if (tournamentStatus === null || typeof tournamentStatus === 'undefined') {
      return ''
    } else if (tournamentStatus === 1 || tournamentStatus.toString().toLowerCase() === '近日開始') {
      return '<span class="badge tournament-status-upcoming border-0 ' + extraCss + '">' + statusText + '</span>'
    } else if (tournamentStatus === 2 || tournamentStatus.toString().toLowerCase() === '期間中') {
      return '<span class="badge tournament-status-ongoing border-0 ' + extraCss + '">' + statusText + '</span>'
    } else if (tournamentStatus === 3 || tournamentStatus.toString().toLowerCase() === '終了') {
      return '<span class="badge tournament-status-ended border-0 ' + extraCss + '">' + statusText + '</span>'
    } else {
      return '<span class="badge tournament-status-undefined border-0 ' + extraCss + '">終了</span>'
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Check if past date
  |--------------------------------------------------------------------------
  */
  static isPast(date) {
    if (!date) {
      return ''
    }
    return moment(moment(date)).isBefore(moment())
  }

  /*
  |--------------------------------------------------------------------------
  | Show day from date
  |--------------------------------------------------------------------------
  */
  static showDay(date, locale, format = 'ddd', extraCss = '') {
    if (!date) {
      return ''
    }
    if (!locale || typeof locale === 'undefined') {
      locale = moment.locale()
    }
    return '<span class="placeholder-day ' + extraCss + '">(' + this.getDay(date, locale, format) + ')</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Get day from date
  |--------------------------------------------------------------------------
  */
  static getDay(date, locale, format = 'ddd') {
    if (!date) {
      return ''
    }
    if (!locale || typeof locale === 'undefined') {
      locale = moment.locale()
    }
    return moment(date).locale(locale).format(format)
  }

  /*
  |--------------------------------------------------------------------------
  | Show date
  |--------------------------------------------------------------------------
  */
  static showDate(date, format = 'MM/DD', locale, extraCss = '') {
    return '<span class="placeholder-date ' + extraCss + '"><i class="fas fa-calendar-check"></i>' +
      this.getDay(date, locale, format) +
      '</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Show Body Temperature
  |--------------------------------------------------------------------------
  */
  static showBodyTemp(bodyTemp, extraCss = '') {
    bodyTemp = bodyTemp === '' ? '' : '<i class="fas fa-thermometer-half" aria-hidden="true"></i>' + (bodyTemp > 0 ? bodyTemp : '— —') + '℃'
    return '<span class="placeholder-thermometer ' + extraCss + '">' + bodyTemp + '</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Show Heat stroke score
  |--------------------------------------------------------------------------
  */
  static showHeatStrokeScore(score, extraCss = '') {
    score = score === null ? '' : score
    return '<span>' + score + '</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Show CTA
  |--------------------------------------------------------------------------
  */
  static showCTA(icon = 'fa-info-circle', extraCss = '') {
    return '<span class="cta ' + extraCss + '">' +
      '<i class="fas ' + icon + ' font-16 text-primary" aria-hidden="true"></i>' +
      '</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Show enter status
  |--------------------------------------------------------------------------
  | enterStatus:
  | 0 = No input,
  | 1 = Good,
  | 2 = Bad,
  */
  static showEnterStatus(enterStatus, extraCss = '') {
    if (enterStatus === null || typeof enterStatus === 'undefined') {
      return ''
    } else if (enterStatus === 0 || enterStatus.toString().toLowerCase() === 'no input') {
      return '<div class="enter-status enter-status-empty ' + extraCss + '">' +
        '<i class="fas fa-minus font-15 color-88 m-t-50 m-l-50"></i>' +
        '</div>'
    } else if (enterStatus === 1 || enterStatus.toString().toLowerCase() === 'good') {
      return '<div class="enter-status enter-status-good ' + extraCss + '">' +
        '<i class="far fa-circle text-success"></i>' +
        '</div>'
    } else if (enterStatus === 2 || enterStatus.toString().toLowerCase() === 'bad') {
      return '<div class="enter-status enter-status-bad ' + extraCss + '">' +
        '<i class="fas fa-times text-danger"></i>' +
        '</div>'
    } else {
      return '<div class="enter-status enter-status-undefined ' + extraCss + '"></div>'
    }
  }

  /**
   * Detects the current browser and its version number.
   *
   * This util checks the current browser name and version and offers a
   * convenient API to test for specific versions or browsers and whether
   * the current visitor uses a supported browser or not.
   * @returns {Object} An object with keys for browser `name`, `os` and `version`.
   */
  _detectBrowser() {
    this.browser = (function () {
      var ua = navigator.userAgent,
        tem,
        os,
        device,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || []

      if (ua.indexOf('Win') !== -1) os = 'Windows'
      if (ua.indexOf('Mac') !== -1) os = 'Macintosh'
      if (ua.indexOf('Linux') !== -1) os = 'Linux'
      if (ua.indexOf('Android') !== -1) os = 'Android'
      if (ua.indexOf('like Mac') !== -1) os = 'iOS'

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return {name: 'IE', version: tem[1], os: os || ''}
      }

      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem != null) {
          return {name: tem[1].replace('OPR', 'Opera'), version: tem[2], os: os}
        }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']

      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1])
      }

      return {name: M[0], version: M[1], os: os}
    })()
  }

  /* Browser detection: END*/

  /**
   * Checks if the current browser is supported by
   *
   * @returns {Boolean}
   */
  isSupported() {
    if (this.unsupportedBrowsers.hasOwnProperty(this.browser.name)) {
      if (+this.browser.version > this.unsupportedBrowsers[this.browser.name]) {
        return true
      }
    }

    return false
  }

}
