import { LoginApi, UserApi, ApiConfig, SystemApi} from './index'
import moment from 'moment'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'
import '../scss/toastify-custom.scss'
import store from '../../store'
import Helper from './helper'

export default class {
    constructor () {

    }

    static getBasePath () {
        return gBasePath
    }

    static getClientKey () {
        return gClientKey
    }

    /**
     *
     * @returns {LoginApi}
     */
    static createLoginApi () {
        // Get the language setting stored in the cookie. 'ja-JP' or 'en-US'
        const lang = Helper.xLanguage()
        const basePath = gBasePath
        const clientKey = gClientKey
        const headers = {'X-Client-Key': clientKey, 'X-Language': lang}
        const apiConfig = new ApiConfig(basePath, headers)
        const api = new LoginApi(apiConfig)
        return api
    }

    /**
     *
     * @param apiToken
     * @returns {UserApi}
     */
    static createUserApi (apiToken) {
        // Get the language setting stored in the cookie. 'ja-JP' or 'en-US'
        const lang = Helper.xLanguage()
        const basePath = gBasePath
        const headers = {'X-API-Token': apiToken, 'X-Language': lang}
        const apiConfig = new ApiConfig(basePath, headers)
        const api = new UserApi(apiConfig)
        return api
    }

    /**
     *
     * @param apiToken
     * @returns {UserApi}
     */
    static createSystemApi (apiToken) {
        // Get the language setting stored in the cookie. 'ja-JP' or 'en-US'
        const lang = Helper.xLanguage()
        const basePath = gBasePath
        const headers = {'X-API-Token': apiToken, 'X-Language': lang}
        const apiConfig = new ApiConfig(basePath, headers)
        const api = new SystemApi(apiConfig)
        return api
    }

    /**
     * オブジェクトから安全にプロパティの値を取得する
     * @param {Object} obj
     * @param {String} key
     * @param {*} defaultValue
     * @returns {*}
     */
    static getProperty (obj, key, defaultValue = null) {
        if (obj.hasOwnProperty(key)) {
            return obj[key]
        } else {
            return defaultValue
        }
    }

    /**********************************************************/
    /* 保存処理 */
    /**********************************************************/
    static saveConfig (config) {
        localStorage.setItem('config', JSON.stringify(config))
    }

    static loadConfig () {
        return JSON.parse(localStorage.getItem('config'))
    }

    static saveFirstLogin () {
        localStorage.setItem('first_login', 1)

        // For compatibility
        document.cookie = "first_login";
    }
    static isFirstLogin() {
        console.log("first_login= " + localStorage.getItem('first_login'));
        if (localStorage.getItem('first_login') == 1) {
            return false
        }

        // For compatibility
        if(document.cookie != ""){
            var tmp = document.cookie.split('; ');
            for(var i=0; i<tmp.length; i++){
                // Consider safari
                if (tmp[i] == "first_login" || tmp[i] == "first_login=") {
                    return false
                }
            }
        }

        return true;
    }

    /**********************************************************/
    // Convert date format to "yyyy/mm/dd"
    /**********************************************************/
    static dateFormatConvert (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('YYYY/MM/DD')
    }

    static dateFormatConvertJST (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date)).tz('Asia/Tokyo')
        return obj.format('YYYY/MM/DD')
    }

    static dateFormatConvertForDatePicker (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('YYYY-MM-DD')
    }
    static getYear (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('YYYY')
    }

    static getMonth (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('MM')
    }

    static getDate (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        return obj.format('DD')
    }
    static dateFormatConvertWeekDay (date) {
        if (date === null) {
            return ''
        }
        const obj = moment(new Date(date));
        obj.locale("ja");
        return obj.format('YYYY/MM/DD(ddd)')
    }
    static isToday(dateString) {
        let specifiedDate = new Date(dateString);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        return specifiedDate.toDateString() === today.toDateString();
    }

    /**********************************************************/
    // get gender
    /**********************************************************/
    static getGender (gender) {
        switch (gender) {
            case 1:
                return '男性'
            case 2:
                return '女性'
            case 3:
                return '回答しない'
        }
    }

    /**********************************************************/
    // get getLatestPcrTestResult
    /**********************************************************/
    static getLatestPcrTestResult (getLatestPcrTestResult) {
        switch (getLatestPcrTestResult) {
            case -1:
                return '陰性'
            case 0:
                return '未検査'
            case 1:
                return '陽性'
        }
    }

    /**********************************************************/
    // get hasCoronaVaccination
    /**********************************************************/
    static getHasCoronaVaccination (hasCoronaVaccination) {
        if (hasCoronaVaccination) {
            return 'あり (2回目)'
        } else {
            return 'なし, 1回目'
        }
    }

    /**********************************************************/
    // get tournamentStatus
    /**********************************************************/
    static getTournamentStatus (status) {
        switch (status) {
            case 1:
                return "近日開始";
            case 2:
                return "期間中";
            case 3:
                return "終了";
            default:
                return ""
        }
    }

    /**********************************************************/
    // get error list
    /**********************************************************/
    static getErrorList (response, errors) {
        if (response !== null && typeof(response) !== "undefined") {
            const res = response.data
            if (res.validationErrors !== null) {
                res.validationErrors.forEach(e => Apv.$set(errors, e.name, e.message))
                return errors
            } else {
                // Other error
            }
        }
        return [];
    }

    static isTokenError (response) {
        if (response !== null && typeof(response) !== "undefined") {
            const res = response.data
            if (res.result === "ERR_EXPIRE_TOKEN" || res.result === "ERR_INVALID_TOKEN") {
                store.commit('configToken', null)
                store.commit('configUserId', null)
                return true
            }
        }
        return false
    }

    static checkFirstLogin (response) {
        if (response !== null && typeof(response) !== "undefined") {
            const res = response.data
            if (res.result === "ERR_LOGIN_REQUIRE_BIRTHDAY") {
                return true
            }
        }
        return false
    }

    static handleAPIError (error, message = null) {
        if (error.response !== null && typeof(error.response) !== "undefined") {
            // Is API Error?
            if (error.response.data !== null && typeof(error.response.data) !== "undefined") {

                // Is validation error?
                const res = error.response.data
                if (res.validationErrors !== null) {
                    return;
                }

                // Is known error?
                if (res.result === "ERR_EXPIRE_TOKEN" || res.result === "ERR_INVALID_TOKEN" || res.result === "ERR_LOGIN_REQUIRE_BIRTHDAY") {
                    return;
                }
            }

            const mes = message ? message : "サーバーとの通信に失敗しました。しばらくしてから再度お試しください。";
            this.showToast(mes, true)
        } else {
            // JavaScript error
        }
    }

    /**********************************************************/
    // get error list
    /**********************************************************/
    static showToast (message, danger = false) {
        // Hide old toast
        const allToasts = document.getElementsByClassName("toastify");
        while (allToasts.length) {
            allToasts.item(0).remove()
        }

        const agent = (new Helper)
        message = agent.browser.name === 'IE' ? '<div class="message">' + message + '</div>' : message
        const className = danger ? "toast-alert-danger" : "toast-alert"
        
        // https://github.com/apvarun/toastify-js#readme
        Toastify({
            text: message,
            duration: 3000,
            newWindow: false,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            className: className,
            stopOnFocus: false,
            onClick: function(){} // Callback after click
        }).showToast();
    }
}
