var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"ml-4 loader"},[_c('Loader')],1):_c('div',{staticClass:"layout-wrapper",attrs:{"id":"layout-wrapper"}},[(_vm.$route.name !== 'Login'
    || _vm.$route.name !== 'LoginWithDob'
    || _vm.$route.name !== 'InitialLoginFirst'
    || _vm.$route.name !== 'InitialLoginSecond'
    || _vm.$route.name !== 'InitialLoginThird'
    || _vm.$route.name !== 'ResetPassword'
    || _vm.$route.name !== 'ResetPasswordEmailSent'
    || _vm.$route.name !== 'ChangePassword')?_c('NavBar'):_vm._e(),(_vm.$route.name)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('main',{staticClass:"main col ml-sm-auto",class:'main-' + _vm.$route.name.toLowerCase(),attrs:{"id":"main","role":"main"}},[(_vm.$route.name)?_c('div',{staticClass:"content",class:_vm.$route.name.toLowerCase()},[_vm._t("default")],2):_vm._e()]),_c('Footer')],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }