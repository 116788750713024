/**
 * Web API
 * API Version 1.0.0
 */
export class ApiConfig {
    constructor (basepath = "http://localhost/api/ip", headers = {}) {
        /**
         * エンドポイント
         * @type {string}
         */
        this.basepath = basepath;

        /**
         * 追加のヘッダ
         * @type {Object}
         */
        this.headers = headers;
    }
}


export class LoginApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * loginPostの結果コールバック関数
     *
     * @callback loginPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?Authenticated } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Log in with your account
     * @param { Credentials } item Set request parameters to login
     * @param { loginPostCallback } callback コールバック
     */
    loginPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/login";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Log in with your account
     * @param { Credentials } item Set request parameters to login
     * @returns { Promise.<Authenticated> }
     */
    async loginPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.loginPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * loginRegisterFormKeyGetの結果コールバック関数
     *
     * @callback loginRegisterFormKeyGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?RegisterFormResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get register form.
     * @param { string } key Tournament URL key
     * @param { loginRegisterFormKeyGetCallback } callback コールバック
     */
    loginRegisterFormKeyGet (key, callback) {
        if (key === void 0) { key = null; }

        var url = this._config.basepath + "/login/registerForm/{key}"
                .replace('{' + 'key' + '}', encodeURIComponent(String(key)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get register form.
     * @param { string } key Tournament URL key
     * @returns { Promise.<RegisterFormResult> }
     */
    async loginRegisterFormKeyGetAsync(key) {
        return new Promise((resolve, reject) => {
            this.loginRegisterFormKeyGet(key, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * loginRegisterPostの結果コールバック関数
     *
     * @callback loginRegisterPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?EmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Register the user.
     * @param { SetRegister } item Set request parameters to register user
     * @param { loginRegisterPostCallback } callback コールバック
     */
    loginRegisterPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/login/register";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Register the user.
     * @param { SetRegister } item Set request parameters to register user
     * @returns { Promise.<EmptyResult> }
     */
    async loginRegisterPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.loginRegisterPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * loginResetPasswordFinishPostの結果コールバック関数
     *
     * @callback loginResetPasswordFinishPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?EmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Password reset completion process
     * @param { SetResetPasswordFinish } item Set request parameters to reset
     * @param { loginResetPasswordFinishPostCallback } callback コールバック
     */
    loginResetPasswordFinishPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/login/resetPasswordFinish";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Password reset completion process
     * @param { SetResetPasswordFinish } item Set request parameters to reset
     * @returns { Promise.<EmptyResult> }
     */
    async loginResetPasswordFinishPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.loginResetPasswordFinishPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * loginResetPasswordPostの結果コールバック関数
     *
     * @callback loginResetPasswordPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?EmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Reset password
     * @param { SetResetPassword } item Set request parameters to reset
     * @param { loginResetPasswordPostCallback } callback コールバック
     */
    loginResetPasswordPost (item, callback) {
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/login/resetPassword";

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Reset password
     * @param { SetResetPassword } item Set request parameters to reset
     * @returns { Promise.<EmptyResult> }
     */
    async loginResetPasswordPostAsync(item) {
        return new Promise((resolve, reject) => {
            this.loginResetPasswordPost(item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class SystemApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * systemNationalitiesGetの結果コールバック関数
     *
     * @callback systemNationalitiesGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?NationalityItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get nationalities
     * @param { systemNationalitiesGetCallback } callback コールバック
     */
    systemNationalitiesGet (callback) {

        var url = this._config.basepath + "/system/nationalities";

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get nationalities
     * @returns { Promise.<NationalityItems> }
     */
    async systemNationalitiesGetAsync() {
        return new Promise((resolve, reject) => {
            this.systemNationalitiesGet((error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * systemRolesGetの結果コールバック関数
     *
     * @callback systemRolesGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?RoleItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get roles
     * @param { number } admin 0: Set to do not include an administrator. 1: Set to include an administrator. 
     * @param { systemRolesGetCallback } callback コールバック
     */
    systemRolesGet (admin, callback) {
        if (admin === void 0) { admin = null; }

        var url = this._config.basepath + "/system/roles";

        var query = {
            "admin": admin
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get roles
     * @param { number } admin 0: Set to do not include an administrator. 1: Set to include an administrator. 
     * @returns { Promise.<RoleItems> }
     */
    async systemRolesGetAsync(admin) {
        return new Promise((resolve, reject) => {
            this.systemRolesGet(admin, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}

export class UserApi {
    /**
     * @param {?ApiConfig} config APIコンフィグ
     * @constructor
     */
    constructor (config) {
        if (config === void 0) { config = new ApiConfig(); }
        this._config = config;
    }



    /**
     * usersUserIdChangePasswordPostの結果コールバック関数
     *
     * @callback usersUserIdChangePasswordPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?EmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update a password
     * @param { string } userId User Id
     * @param { SetChangePassword } item Request data
     * @param { usersUserIdChangePasswordPostCallback } callback コールバック
     */
    usersUserIdChangePasswordPost (userId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/users/{userId}/changePassword"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update a password
     * @param { string } userId User Id
     * @param { SetChangePassword } item Request data
     * @returns { Promise.<EmptyResult> }
     */
    async usersUserIdChangePasswordPostAsync(userId, item) {
        return new Promise((resolve, reject) => {
            this.usersUserIdChangePasswordPost(userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdDeletePostの結果コールバック関数
     *
     * @callback usersUserIdDeletePostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?EmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Delete a user account
     * @param { string } userId User Id
     * @param { SetDeleteUser } item Request data
     * @param { usersUserIdDeletePostCallback } callback コールバック
     */
    usersUserIdDeletePost (userId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/users/{userId}/delete"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Delete a user account
     * @param { string } userId User Id
     * @param { SetDeleteUser } item Request data
     * @returns { Promise.<EmptyResult> }
     */
    async usersUserIdDeletePostAsync(userId, item) {
        return new Promise((resolve, reject) => {
            this.usersUserIdDeletePost(userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdGetの結果コールバック関数
     *
     * @callback usersUserIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?User } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get a user account
     * @param { string } userId User Id
     * @param { usersUserIdGetCallback } callback コールバック
     */
    usersUserIdGet (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get a user account
     * @param { string } userId User Id
     * @returns { Promise.<User> }
     */
    async usersUserIdGetAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdGet(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdHealthStatusGetの結果コールバック関数
     *
     * @callback usersUserIdHealthStatusGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?HealthStatusResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get health status.
     * @param { string } userId User Id
     * @param { usersUserIdHealthStatusGetCallback } callback コールバック
     */
    usersUserIdHealthStatusGet (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}/healthStatus"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get health status.
     * @param { string } userId User Id
     * @returns { Promise.<HealthStatusResult> }
     */
    async usersUserIdHealthStatusGetAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdHealthStatusGet(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdLogoutPostの結果コールバック関数
     *
     * @callback usersUserIdLogoutPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?EmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Logout
     * @param { string } userId User Id
     * @param { usersUserIdLogoutPostCallback } callback コールバック
     */
    usersUserIdLogoutPost (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}/logout"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Logout
     * @param { string } userId User Id
     * @returns { Promise.<EmptyResult> }
     */
    async usersUserIdLogoutPostAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdLogoutPost(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdNoInputGetの結果コールバック関数
     *
     * @callback usersUserIdNoInputGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?NoInputResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get the number of no input days for User
     * @param { string } userId User Id
     * @param { usersUserIdNoInputGetCallback } callback コールバック
     */
    usersUserIdNoInputGet (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}/noInput"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get the number of no input days for User
     * @param { string } userId User Id
     * @returns { Promise.<NoInputResult> }
     */
    async usersUserIdNoInputGetAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdNoInputGet(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdQrGetの結果コールバック関数
     *
     * @callback usersUserIdQrGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?QrResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get QR
     * @param { string } userId User Id
     * @param { usersUserIdQrGetCallback } callback コールバック
     */
    usersUserIdQrGet (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}/qr"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get QR
     * @param { string } userId User Id
     * @returns { Promise.<QrResult> }
     */
    async usersUserIdQrGetAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdQrGet(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdQuestionnaireGetの結果コールバック関数
     *
     * @callback usersUserIdQuestionnaireGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?QuestionnaireSheet } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Acquisition of medical questionnaire items
     * @param { string } userId User Id
     * @param { ?date } date Date
     * @param { ?number } past (deprecated)Input for the last 14 days? Default is 0.
     * @param { usersUserIdQuestionnaireGetCallback } callback コールバック
     */
    usersUserIdQuestionnaireGet (userId, date, past, callback) {
        if (userId === void 0) { userId = null; }
        if (date === void 0) { date = null; }
        if (past === void 0) { past = null; }

        var url = this._config.basepath + "/users/{userId}/questionnaire"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "date": date,
            "past": past
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Acquisition of medical questionnaire items
     * @param { string } userId User Id
     * @param { ?date } date Date
     * @param { ?number } past (deprecated)Input for the last 14 days? Default is 0.
     * @returns { Promise.<QuestionnaireSheet> }
     */
    async usersUserIdQuestionnaireGetAsync(userId, date, past) {
        return new Promise((resolve, reject) => {
            this.usersUserIdQuestionnaireGet(userId, date, past, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdQuestionnairePostの結果コールバック関数
     *
     * @callback usersUserIdQuestionnairePostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?HealthStatusResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Submission of medical questionnaire items
     * @param { string } userId User Id
     * @param { SetQuestionnaireSheet } item Request data
     * @param { usersUserIdQuestionnairePostCallback } callback コールバック
     */
    usersUserIdQuestionnairePost (userId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/users/{userId}/questionnaire"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Submission of medical questionnaire items
     * @param { string } userId User Id
     * @param { SetQuestionnaireSheet } item Request data
     * @returns { Promise.<HealthStatusResult> }
     */
    async usersUserIdQuestionnairePostAsync(userId, item) {
        return new Promise((resolve, reject) => {
            this.usersUserIdQuestionnairePost(userId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdRecordsDetailGetの結果コールバック関数
     *
     * @callback usersUserIdRecordsDetailGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?RecordDetail } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get record
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament Id
     * @param { date } date Date
     * @param { usersUserIdRecordsDetailGetCallback } callback コールバック
     */
    usersUserIdRecordsDetailGet (userId, tournamentId, date, callback) {
        if (userId === void 0) { userId = null; }
        if (tournamentId === void 0) { tournamentId = null; }
        if (date === void 0) { date = null; }

        var url = this._config.basepath + "/users/{userId}/records/detail"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "tournamentId": tournamentId,
            "date": date
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get record
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament Id
     * @param { date } date Date
     * @returns { Promise.<RecordDetail> }
     */
    async usersUserIdRecordsDetailGetAsync(userId, tournamentId, date) {
        return new Promise((resolve, reject) => {
            this.usersUserIdRecordsDetailGet(userId, tournamentId, date, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdRecordsGetの結果コールバック関数
     *
     * @callback usersUserIdRecordsGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?RecordItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get record
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament Id
     * @param { usersUserIdRecordsGetCallback } callback コールバック
     */
    usersUserIdRecordsGet (userId, tournamentId, callback) {
        if (userId === void 0) { userId = null; }
        if (tournamentId === void 0) { tournamentId = null; }

        var url = this._config.basepath + "/users/{userId}/records"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "tournamentId": tournamentId
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get record
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament Id
     * @returns { Promise.<RecordItems> }
     */
    async usersUserIdRecordsGetAsync(userId, tournamentId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdRecordsGet(userId, tournamentId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdTournamentsGetの結果コールバック関数
     *
     * @callback usersUserIdTournamentsGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?TournamentItems } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get Tournament for User
     * @param { string } userId User Id
     * @param { usersUserIdTournamentsGetCallback } callback コールバック
     */
    usersUserIdTournamentsGet (userId, callback) {
        if (userId === void 0) { userId = null; }

        var url = this._config.basepath + "/users/{userId}/tournaments"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get Tournament for User
     * @param { string } userId User Id
     * @returns { Promise.<TournamentItems> }
     */
    async usersUserIdTournamentsGetAsync(userId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdTournamentsGet(userId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdTournamentsTournamentIdGetの結果コールバック関数
     *
     * @callback usersUserIdTournamentsTournamentIdGetCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?TournamentDetail } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Get Tournament for User
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament id
     * @param { usersUserIdTournamentsTournamentIdGetCallback } callback コールバック
     */
    usersUserIdTournamentsTournamentIdGet (userId, tournamentId, callback) {
        if (userId === void 0) { userId = null; }
        if (tournamentId === void 0) { tournamentId = null; }

        var url = this._config.basepath + "/users/{userId}/tournaments/{tournamentId}"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "get",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Get Tournament for User
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament id
     * @returns { Promise.<TournamentDetail> }
     */
    async usersUserIdTournamentsTournamentIdGetAsync(userId, tournamentId) {
        return new Promise((resolve, reject) => {
            this.usersUserIdTournamentsTournamentIdGet(userId, tournamentId, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdTournamentsTournamentIdRemindMailPostの結果コールバック関数
     *
     * @callback usersUserIdTournamentsTournamentIdRemindMailPostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?TournamentDetail } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Set up reminder emails.
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament id
     * @param { SetRemindMail } item Request data
     * @param { usersUserIdTournamentsTournamentIdRemindMailPostCallback } callback コールバック
     */
    usersUserIdTournamentsTournamentIdRemindMailPost (userId, tournamentId, item, callback) {
        if (userId === void 0) { userId = null; }
        if (tournamentId === void 0) { tournamentId = null; }
        if (item === void 0) { item = null; }

        var url = this._config.basepath + "/users/{userId}/tournaments/{tournamentId}/remindMail"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)))
                .replace('{' + 'tournamentId' + '}', encodeURIComponent(String(tournamentId)));

        var query = {
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Set up reminder emails.
     * @param { string } userId User Id
     * @param { number } tournamentId Tournament id
     * @param { SetRemindMail } item Request data
     * @returns { Promise.<TournamentDetail> }
     */
    async usersUserIdTournamentsTournamentIdRemindMailPostAsync(userId, tournamentId, item) {
        return new Promise((resolve, reject) => {
            this.usersUserIdTournamentsTournamentIdRemindMailPost(userId, tournamentId, item, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }



    /**
     * usersUserIdUpdatePostの結果コールバック関数
     *
     * @callback usersUserIdUpdatePostCallback
     * @param { ?Object } error エラーオブジェクト
     * @param { ?EmptyResult } data データ
     * @param { Object } response レスポンス情報
     */

    /**
     * Update a user account
     * @param { string } userId User Id
     * @param { SetUpdateUser } item Request data
     * @param { ?number } dryRun Only verification is performed without registration. If it is 1, only verification is performed. 
     * @param { usersUserIdUpdatePostCallback } callback コールバック
     */
    usersUserIdUpdatePost (userId, item, dryRun, callback) {
        if (userId === void 0) { userId = null; }
        if (item === void 0) { item = null; }
        if (dryRun === void 0) { dryRun = 0; }

        var url = this._config.basepath + "/users/{userId}/update"
                .replace('{' + 'userId' + '}', encodeURIComponent(String(userId)));

        var query = {
            "dryRun": dryRun
        };
        var request_data = {};
        request_data = item;

        var headers = {
            "Content-Type": "application/json"
        };
        var request_headers = mergeObject(this._config.headers, headers);

        axios({
            url: url,
            method: "post",
            params: query,
            data: request_data,
            headers: request_headers
        }).then(function (response) {
            var data = response.data;
            callback(null, data, response);
        }).catch(function (error) {
            callback(error, null, null);
        });
    }

    /**
     * Update a user account
     * @param { string } userId User Id
     * @param { SetUpdateUser } item Request data
     * @param { ?number } dryRun Only verification is performed without registration. If it is 1, only verification is performed. 
     * @returns { Promise.<EmptyResult> }
     */
    async usersUserIdUpdatePostAsync(userId, item, dryRun) {
        return new Promise((resolve, reject) => {
            this.usersUserIdUpdatePost(userId, item, dryRun, (error, data, response) => {
                if (error !== null) {
                    reject(error);
                    return;
                }
                resolve(data);
            });
        });
    }


}


/**
 * authenticated object
 * @constructor
 */
export class Authenticated {
    constructor() {
        /**
         * @type { User } 
         */
        this.user = null;
        /**
         * @type { string } API token key
         */
        this.apiToken = null;
        /**
         * @type { Date } Token time to live
         */
        this.tokenExpiredAt = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { Authenticated }
     */
    static createFromJson (jsonObject) {
        const obj = new Authenticated();
        if (typeof(jsonObject.user) !== "undefined") {
            obj.user = jsonObject.user;
        }
        if (typeof(jsonObject.apiToken) !== "undefined") {
            obj.apiToken = jsonObject.apiToken;
        }
        if (typeof(jsonObject.tokenExpiredAt) !== "undefined") {
            obj.tokenExpiredAt = jsonObject.tokenExpiredAt;
        }
        return obj;
  }
}

/*
var request_data = new Authenticated()
request_data.user = null;
request_data.apiToken = null;
request_data.tokenExpiredAt = null;
*/

/**
 * credentials object
 * @constructor
 */
export class Credentials {
    constructor() {
        /**
         * @type { string } E-mail
         */
        this.email = null;
        /**
         * @type { string } Password
         */
        this.password = null;
        /**
         * @type { ?date } Birthday
         */
        this.birthday = null;
        /**
         * @type { ?boolean } Remember flag * true: Preserve the login details * false: Do not preserve the login details 
         */
        this.remember = null;
        /**
         * @type { ?string } Tournament Key
         */
        this.tournamentKey = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { Credentials }
     */
    static createFromJson (jsonObject) {
        const obj = new Credentials();
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.password) !== "undefined") {
            obj.password = jsonObject.password;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.remember) !== "undefined") {
            obj.remember = jsonObject.remember;
        }
        if (typeof(jsonObject.tournamentKey) !== "undefined") {
            obj.tournamentKey = jsonObject.tournamentKey;
        }
        return obj;
  }
}

/*
var request_data = new Credentials()
request_data.email = null;
request_data.password = null;
request_data.birthday = null;
request_data.remember = null;
request_data.tournamentKey = null;
*/

/**
 * Returns an empty object.
 * @constructor
 */
export class EmptyResult {
    constructor() {
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { EmptyResult }
     */
    static createFromJson (jsonObject) {
        const obj = new EmptyResult();
        return obj;
  }
}

/*
var request_data = new EmptyResult()
*/

/**
 * Error object If result is `ERR_VALIDATION`, contains the key and message where the error occurred. If the request data is nested, use a period. If there is an error in the array, the zero-based indexes are combined to create the key name.  Example: ``` {   \"result\": \"ERR_VALIDATION\",   \"message\": \"The given data was invalid.\",   \"validationErrors\" = [     {       \"name\": \"foo\",       \"message\": \"foo error message\",     },     {       \"name\": \"bar.baz\",       \"message\": \"baz error message\",     },     {       \"name\": \"corge.0.grault\",       \"message\": \"grault error message\"     }   ] } ``` 
 * @constructor
 */
export class ErrorResult {
    constructor() {
        /**
         * @type { string } Result description * ERR_UNKNOWN: An error has occurred on the server side.<br> * ERR_NOTFOUND: The specified page cannot be found.<br> * ERR_FORBIDDEN: You do not have API permissions. The client key or token specification is invalid.<br> * ERR_EXPIRE_TOKEN: Token has expired.<br> * ERR_INVALID_TOKEN: Invalid Token<br> * ERR_LOGIN_REQUIRE_BIRTHDAY: Need to enter the password when logging in.<br> * ERR_VALIDATION: The requested value has an invalid value. Keys and messages are stored in pairs in `validationErrors`.<br> * ERR_TOO_MANY_REQUESTS: Access is denied because there are too many requests.        <br> 
         */
        this.result = null;
        /**
         * @type { string } Error message
         */
        this.message = null;
        /**
         * @type { ?Array.<ValidationError> } 
         */
        this.validationErrors = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { ErrorResult }
     */
    static createFromJson (jsonObject) {
        const obj = new ErrorResult();
        if (typeof(jsonObject.result) !== "undefined") {
            obj.result = jsonObject.result;
        }
        if (typeof(jsonObject.message) !== "undefined") {
            obj.message = jsonObject.message;
        }
        if (typeof(jsonObject.validationErrors) !== "undefined") {
            if (jsonObject.validationErrors !== null) {
                obj.validationErrors = [];
                for (let i = 0; i < jsonObject.validationErrors.length; i++) {
                    const item = ValidationError.createFromJson(jsonObject.validationErrors[i]);
                    obj.validationErrors.push(item);
                }
            } else {
                obj.validationErrors = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new ErrorResult()
request_data.result = null;
request_data.message = null;
request_data.validationErrors = null;
*/

/**
 * Health status
 * @constructor
 */
export class HealthStatusResult {
    constructor() {
        /**
         * @type { number } Health status * No input = 0 * Abnormality = -1 * Normality = 1 
         */
        this.healthStatus = null;
        /**
         * @type { NoInputResult } 
         */
        this.noInput = null;
        /**
         * @type { number } Heat stroke score 
         */
        this.heatStrokeScore = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { HealthStatusResult }
     */
    static createFromJson (jsonObject) {
        const obj = new HealthStatusResult();
        if (typeof(jsonObject.healthStatus) !== "undefined") {
            obj.healthStatus = jsonObject.healthStatus;
        }
        if (typeof(jsonObject.noInput) !== "undefined") {
            obj.noInput = jsonObject.noInput;
        }
        if (typeof(jsonObject.heatStrokeScore) !== "undefined") {
            obj.heatStrokeScore = jsonObject.heatStrokeScore;
        }
        return obj;
  }
}

/*
var request_data = new HealthStatusResult()
request_data.healthStatus = null;
request_data.noInput = null;
request_data.heatStrokeScore = null;
*/

/**
 * Nationality object
 * @constructor
 */
export class Nationality {
    constructor() {
        /**
         * @type { number } Nationality Id
         */
        this.nationalityId = null;
        /**
         * @type { string } Nationality name
         */
        this.nationalityName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { Nationality }
     */
    static createFromJson (jsonObject) {
        const obj = new Nationality();
        if (typeof(jsonObject.nationalityId) !== "undefined") {
            obj.nationalityId = jsonObject.nationalityId;
        }
        if (typeof(jsonObject.nationalityName) !== "undefined") {
            obj.nationalityName = jsonObject.nationalityName;
        }
        return obj;
  }
}

/*
var request_data = new Nationality()
request_data.nationalityId = null;
request_data.nationalityName = null;
*/

/**
 * List of nationality object
 * @constructor
 */
export class NationalityItems {
    constructor() {
        /**
         * @type { Array.<Nationality> } List of nationalities. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { NationalityItems }
     */
    static createFromJson (jsonObject) {
        const obj = new NationalityItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = Nationality.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new NationalityItems()
request_data.items = null;
*/

/**
 * No input result
 * @constructor
 */
export class NoInputResult {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.numberOfDays = null;
        /**
         * @type { Array.<date> } 
         */
        this.dates = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { NoInputResult }
     */
    static createFromJson (jsonObject) {
        const obj = new NoInputResult();
        if (typeof(jsonObject.numberOfDays) !== "undefined") {
            obj.numberOfDays = jsonObject.numberOfDays;
        }
        if (typeof(jsonObject.dates) !== "undefined") {
            if (jsonObject.dates !== null) {
                obj.dates = [];
                for (let i = 0; i < jsonObject.dates.length; i++) {
                    const item = date.createFromJson(jsonObject.dates[i]);
                    obj.dates.push(item);
                }
            } else {
                obj.dates = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new NoInputResult()
request_data.numberOfDays = null;
request_data.dates = null;
*/

/**
 * Qr result object
 * @constructor
 */
export class QrResult {
    constructor() {
        /**
         * @type { string } QR code Base64 string
         */
        this.qrBase64Image = null;
        /**
         * @type { string } Entry No (User ID)
         */
        this.entryNo = null;
        /**
         * @type { string } User name (Last name and first name)
         */
        this.userName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { QrResult }
     */
    static createFromJson (jsonObject) {
        const obj = new QrResult();
        if (typeof(jsonObject.qrBase64Image) !== "undefined") {
            obj.qrBase64Image = jsonObject.qrBase64Image;
        }
        if (typeof(jsonObject.entryNo) !== "undefined") {
            obj.entryNo = jsonObject.entryNo;
        }
        if (typeof(jsonObject.userName) !== "undefined") {
            obj.userName = jsonObject.userName;
        }
        return obj;
  }
}

/*
var request_data = new QrResult()
request_data.qrBase64Image = null;
request_data.entryNo = null;
request_data.userName = null;
*/

/**
 * Questionnaire choice object
 * @constructor
 */
export class QuestionnairChoice {
    constructor() {
        /**
         * @type { string } Choice title
         */
        this.choiceTitle = null;
        /**
         * @type { string } Choice value
         */
        this.choiceValue = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { QuestionnairChoice }
     */
    static createFromJson (jsonObject) {
        const obj = new QuestionnairChoice();
        if (typeof(jsonObject.choiceTitle) !== "undefined") {
            obj.choiceTitle = jsonObject.choiceTitle;
        }
        if (typeof(jsonObject.choiceValue) !== "undefined") {
            obj.choiceValue = jsonObject.choiceValue;
        }
        return obj;
  }
}

/*
var request_data = new QuestionnairChoice()
request_data.choiceTitle = null;
request_data.choiceValue = null;
*/

/**
 * Questionnaire object
 * @constructor
 */
export class Questionnaire {
    constructor() {
        /**
         * @type { number } Questionnaire Id
         */
        this.questionnaireItemId = null;
        /**
         * @type { string } Questionnaire Title
         */
        this.questionnaireTitle = null;
        /**
         * @type { number } Questionnaire Type * 1 = Radio button 
         */
        this.questionnaireType = null;
        /**
         * @type { ?Array.<QuestionnairChoice> } Questionnaire Values
         */
        this.questionnaireChoice = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { Questionnaire }
     */
    static createFromJson (jsonObject) {
        const obj = new Questionnaire();
        if (typeof(jsonObject.questionnaireItemId) !== "undefined") {
            obj.questionnaireItemId = jsonObject.questionnaireItemId;
        }
        if (typeof(jsonObject.questionnaireTitle) !== "undefined") {
            obj.questionnaireTitle = jsonObject.questionnaireTitle;
        }
        if (typeof(jsonObject.questionnaireType) !== "undefined") {
            obj.questionnaireType = jsonObject.questionnaireType;
        }
        if (typeof(jsonObject.questionnaireChoice) !== "undefined") {
            if (jsonObject.questionnaireChoice !== null) {
                obj.questionnaireChoice = [];
                for (let i = 0; i < jsonObject.questionnaireChoice.length; i++) {
                    const item = QuestionnairChoice.createFromJson(jsonObject.questionnaireChoice[i]);
                    obj.questionnaireChoice.push(item);
                }
            } else {
                obj.questionnaireChoice = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new Questionnaire()
request_data.questionnaireItemId = null;
request_data.questionnaireTitle = null;
request_data.questionnaireType = null;
request_data.questionnaireChoice = null;
*/

/**
 * Questionnaire list object. If this flag is true, 14 days can be processed together.
 * @constructor
 */
export class QuestionnaireSheet {
    constructor() {
        /**
         * @type { boolean } Input for the last 14 days?
         */
        this.isPastInput = null;
        /**
         * @type { ?date } Input date
         */
        this.date = null;
        /**
         * @type { ?string } Body temp (xx.y)
         */
        this.bodyTemp = null;
        /**
         * @type { ?string } Remarks
         */
        this.remarks = null;
        /**
         * @type { Array.<Questionnaire> } List of questionnaire. 
         */
        this.items = null;
        /**
         * @type { boolean } Is there a heat stroke question?
         */
        this.hasHeatStroke = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { QuestionnaireSheet }
     */
    static createFromJson (jsonObject) {
        const obj = new QuestionnaireSheet();
        if (typeof(jsonObject.isPastInput) !== "undefined") {
            obj.isPastInput = jsonObject.isPastInput;
        }
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.bodyTemp) !== "undefined") {
            obj.bodyTemp = jsonObject.bodyTemp;
        }
        if (typeof(jsonObject.remarks) !== "undefined") {
            obj.remarks = jsonObject.remarks;
        }
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = Questionnaire.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.hasHeatStroke) !== "undefined") {
            obj.hasHeatStroke = jsonObject.hasHeatStroke;
        }
        return obj;
  }
}

/*
var request_data = new QuestionnaireSheet()
request_data.isPastInput = null;
request_data.date = null;
request_data.bodyTemp = null;
request_data.remarks = null;
request_data.items = null;
request_data.hasHeatStroke = null;
*/

/**
 * Record object
 * @constructor
 */
export class Record {
    constructor() {
        /**
         * @type { ?number } Health status | * No input = 0 * Abnormality = -1 * Normality = 1
         */
        this.healthStatus = null;
        /**
         * @type { date } Date
         */
        this.date = null;
        /**
         * @type { ?string } Body temp
         */
        this.bodyTemp = null;
        /**
         * @type { ?string } Remarks
         */
        this.remarks = null;
        /**
         * @type { ?number } Heat stroke score 
         */
        this.heatStrokeScore = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { Record }
     */
    static createFromJson (jsonObject) {
        const obj = new Record();
        if (typeof(jsonObject.healthStatus) !== "undefined") {
            obj.healthStatus = jsonObject.healthStatus;
        }
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.bodyTemp) !== "undefined") {
            obj.bodyTemp = jsonObject.bodyTemp;
        }
        if (typeof(jsonObject.remarks) !== "undefined") {
            obj.remarks = jsonObject.remarks;
        }
        if (typeof(jsonObject.heatStrokeScore) !== "undefined") {
            obj.heatStrokeScore = jsonObject.heatStrokeScore;
        }
        return obj;
  }
}

/*
var request_data = new Record()
request_data.healthStatus = null;
request_data.date = null;
request_data.bodyTemp = null;
request_data.remarks = null;
request_data.heatStrokeScore = null;
*/

/**
 * Record answer object
 * @constructor
 */
export class RecordAnswer {
    constructor() {
        /**
         * @type { string } Title
         */
        this.title = null;
        /**
         * @type { string } Value
         */
        this.value = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { RecordAnswer }
     */
    static createFromJson (jsonObject) {
        const obj = new RecordAnswer();
        if (typeof(jsonObject.title) !== "undefined") {
            obj.title = jsonObject.title;
        }
        if (typeof(jsonObject.value) !== "undefined") {
            obj.value = jsonObject.value;
        }
        return obj;
  }
}

/*
var request_data = new RecordAnswer()
request_data.title = null;
request_data.value = null;
*/

/**
 * Questionnaire object
 * @constructor
 */
export class RecordDetail {
    constructor() {
        /**
         * @type { ?number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { ?string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { ?string } User identification key
         */
        this.userId = null;
        /**
         * @type { number } Status
         */
        this.status = null;
        /**
         * @type { date } Date
         */
        this.date = null;
        /**
         * @type { ?string } Body temp
         */
        this.bodyTemp = null;
        /**
         * @type { ?string } Remarks
         */
        this.remarks = null;
        /**
         * @type { Array.<RecordAnswer> } Record answers
         */
        this.answers = null;
        /**
         * @type { ?number } Heat stroke question tiredness * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionTiredness = null;
        /**
         * @type { ?number } Heat stroke question appetite * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionAppetite = null;
        /**
         * @type { ?number } Heat stroke question sleep * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionSleep = null;
        /**
         * @type { ?number } Heat stroke question sweat * 1: Very good / 非常に良い * 2: Good / 良い * 3: Usually / 普通 * 4: Bad / 悪い * 5: Very bad / 非常に悪い 
         */
        this.heatStrokeQuestionSweat = null;
        /**
         * @type { ?number } Pee color * 1: Light / うすい * 3: Usually / 普通 * 5: Dark / 濃い * 7: Very dark / とても濃い 
         */
        this.peeColor = null;
        /**
         * @type { ?number } Ever had heat stroke * 1: Yes / はい * 2: No　/ いいえ 
         */
        this.everHadHeatStroke = null;
        /**
         * @type { ?number } Heat stroke score 
         */
        this.heatStrokeScore = null;
        /**
         * @type { ?date } Previous date
         */
        this.prevDate = null;
        /**
         * @type { ?date } Next date
         */
        this.nextDate = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { RecordDetail }
     */
    static createFromJson (jsonObject) {
        const obj = new RecordDetail();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.status) !== "undefined") {
            obj.status = jsonObject.status;
        }
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.bodyTemp) !== "undefined") {
            obj.bodyTemp = jsonObject.bodyTemp;
        }
        if (typeof(jsonObject.remarks) !== "undefined") {
            obj.remarks = jsonObject.remarks;
        }
        if (typeof(jsonObject.answers) !== "undefined") {
            if (jsonObject.answers !== null) {
                obj.answers = [];
                for (let i = 0; i < jsonObject.answers.length; i++) {
                    const item = RecordAnswer.createFromJson(jsonObject.answers[i]);
                    obj.answers.push(item);
                }
            } else {
                obj.answers = null;
            }
        }
        if (typeof(jsonObject.heatStrokeQuestionTiredness) !== "undefined") {
            obj.heatStrokeQuestionTiredness = jsonObject.heatStrokeQuestionTiredness;
        }
        if (typeof(jsonObject.heatStrokeQuestionAppetite) !== "undefined") {
            obj.heatStrokeQuestionAppetite = jsonObject.heatStrokeQuestionAppetite;
        }
        if (typeof(jsonObject.heatStrokeQuestionSleep) !== "undefined") {
            obj.heatStrokeQuestionSleep = jsonObject.heatStrokeQuestionSleep;
        }
        if (typeof(jsonObject.heatStrokeQuestionSweat) !== "undefined") {
            obj.heatStrokeQuestionSweat = jsonObject.heatStrokeQuestionSweat;
        }
        if (typeof(jsonObject.peeColor) !== "undefined") {
            obj.peeColor = jsonObject.peeColor;
        }
        if (typeof(jsonObject.everHadHeatStroke) !== "undefined") {
            obj.everHadHeatStroke = jsonObject.everHadHeatStroke;
        }
        if (typeof(jsonObject.heatStrokeScore) !== "undefined") {
            obj.heatStrokeScore = jsonObject.heatStrokeScore;
        }
        if (typeof(jsonObject.prevDate) !== "undefined") {
            obj.prevDate = jsonObject.prevDate;
        }
        if (typeof(jsonObject.nextDate) !== "undefined") {
            obj.nextDate = jsonObject.nextDate;
        }
        return obj;
  }
}

/*
var request_data = new RecordDetail()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.userId = null;
request_data.status = null;
request_data.date = null;
request_data.bodyTemp = null;
request_data.remarks = null;
request_data.answers = null;
request_data.heatStrokeQuestionTiredness = null;
request_data.heatStrokeQuestionAppetite = null;
request_data.heatStrokeQuestionSleep = null;
request_data.heatStrokeQuestionSweat = null;
request_data.peeColor = null;
request_data.everHadHeatStroke = null;
request_data.heatStrokeScore = null;
request_data.prevDate = null;
request_data.nextDate = null;
*/

/**
 * List of record object
 * @constructor
 */
export class RecordItems {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { Array.<Record> } List of records. 
         */
        this.items = null;
        /**
         * @type { ?number } Previous tournament ID
         */
        this.prevTournamentId = null;
        /**
         * @type { ?number } Next tournament ID
         */
        this.nextTournamentId = null;
        /**
         * @type { ?string } Previous tournament name
         */
        this.prevTournamentName = null;
        /**
         * @type { ?string } Next tournament name
         */
        this.nextTournamentName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { RecordItems }
     */
    static createFromJson (jsonObject) {
        const obj = new RecordItems();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = Record.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.prevTournamentId) !== "undefined") {
            obj.prevTournamentId = jsonObject.prevTournamentId;
        }
        if (typeof(jsonObject.nextTournamentId) !== "undefined") {
            obj.nextTournamentId = jsonObject.nextTournamentId;
        }
        if (typeof(jsonObject.prevTournamentName) !== "undefined") {
            obj.prevTournamentName = jsonObject.prevTournamentName;
        }
        if (typeof(jsonObject.nextTournamentName) !== "undefined") {
            obj.nextTournamentName = jsonObject.nextTournamentName;
        }
        return obj;
  }
}

/*
var request_data = new RecordItems()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.userId = null;
request_data.items = null;
request_data.prevTournamentId = null;
request_data.nextTournamentId = null;
request_data.prevTournamentName = null;
request_data.nextTournamentName = null;
*/

/**
 * Register form result info
 * @constructor
 */
export class RegisterFormResult {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { date } Start at
         */
        this.startAt = null;
        /**
         * @type { date } End at
         */
        this.endAt = null;
        /**
         * @type { date } Input start at
         */
        this.inputStartAt = null;
        /**
         * @type { date } Input end at
         */
        this.inputEndAt = null;
        /**
         * @type { number } * Coming soon / 近日開始 = 1 * During the duration / 期間中 = 2 * Ended / 終了 = 3 
         */
        this.status = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { RegisterFormResult }
     */
    static createFromJson (jsonObject) {
        const obj = new RegisterFormResult();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.startAt) !== "undefined") {
            obj.startAt = jsonObject.startAt;
        }
        if (typeof(jsonObject.endAt) !== "undefined") {
            obj.endAt = jsonObject.endAt;
        }
        if (typeof(jsonObject.inputStartAt) !== "undefined") {
            obj.inputStartAt = jsonObject.inputStartAt;
        }
        if (typeof(jsonObject.inputEndAt) !== "undefined") {
            obj.inputEndAt = jsonObject.inputEndAt;
        }
        if (typeof(jsonObject.status) !== "undefined") {
            obj.status = jsonObject.status;
        }
        return obj;
  }
}

/*
var request_data = new RegisterFormResult()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.startAt = null;
request_data.endAt = null;
request_data.inputStartAt = null;
request_data.inputEndAt = null;
request_data.status = null;
*/

/**
 * Role object
 * @constructor
 */
export class RoleItem {
    constructor() {
        /**
         * @type { number } Role Id
         */
        this.roleId = null;
        /**
         * @type { string } Role name
         */
        this.roleName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { RoleItem }
     */
    static createFromJson (jsonObject) {
        const obj = new RoleItem();
        if (typeof(jsonObject.roleId) !== "undefined") {
            obj.roleId = jsonObject.roleId;
        }
        if (typeof(jsonObject.roleName) !== "undefined") {
            obj.roleName = jsonObject.roleName;
        }
        return obj;
  }
}

/*
var request_data = new RoleItem()
request_data.roleId = null;
request_data.roleName = null;
*/

/**
 * List of role object
 * @constructor
 */
export class RoleItems {
    constructor() {
        /**
         * @type { Array.<RoleItem> } List of role. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { RoleItems }
     */
    static createFromJson (jsonObject) {
        const obj = new RoleItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = RoleItem.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new RoleItems()
request_data.items = null;
*/

/**
 * Change password object
 * @constructor
 */
export class SetChangePassword {
    constructor() {
        /**
         * @type { string } Old password
         */
        this.oldPassword = null;
        /**
         * @type { string } New password
         */
        this.newPassword = null;
        /**
         * @type { string } New password confirm
         */
        this.newPasswordConfirm = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetChangePassword }
     */
    static createFromJson (jsonObject) {
        const obj = new SetChangePassword();
        if (typeof(jsonObject.oldPassword) !== "undefined") {
            obj.oldPassword = jsonObject.oldPassword;
        }
        if (typeof(jsonObject.newPassword) !== "undefined") {
            obj.newPassword = jsonObject.newPassword;
        }
        if (typeof(jsonObject.newPasswordConfirm) !== "undefined") {
            obj.newPasswordConfirm = jsonObject.newPasswordConfirm;
        }
        return obj;
  }
}

/*
var request_data = new SetChangePassword()
request_data.oldPassword = null;
request_data.newPassword = null;
request_data.newPasswordConfirm = null;
*/

/**
 * Delete user object
 * @constructor
 */
export class SetDeleteUser {
    constructor() {
        /**
         * @type { string } Authentication password. 
         */
        this.authenticationPassword = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetDeleteUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetDeleteUser();
        if (typeof(jsonObject.authenticationPassword) !== "undefined") {
            obj.authenticationPassword = jsonObject.authenticationPassword;
        }
        return obj;
  }
}

/*
var request_data = new SetDeleteUser()
request_data.authenticationPassword = null;
*/

/**
 * Set questionnaire object
 * @constructor
 */
export class SetQuestionnaire {
    constructor() {
        /**
         * @type { number } Questionnaire Id
         */
        this.questionnaireItemId = null;
        /**
         * @type { string } Questionnaire Value
         */
        this.questionnaireValue = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetQuestionnaire }
     */
    static createFromJson (jsonObject) {
        const obj = new SetQuestionnaire();
        if (typeof(jsonObject.questionnaireItemId) !== "undefined") {
            obj.questionnaireItemId = jsonObject.questionnaireItemId;
        }
        if (typeof(jsonObject.questionnaireValue) !== "undefined") {
            obj.questionnaireValue = jsonObject.questionnaireValue;
        }
        return obj;
  }
}

/*
var request_data = new SetQuestionnaire()
request_data.questionnaireItemId = null;
request_data.questionnaireValue = null;
*/

/**
 * Set questionnaire list object
 * @constructor
 */
export class SetQuestionnaireSheet {
    constructor() {
        /**
         * @type { boolean } Input for the last 14 days?
         */
        this.isPastInput = null;
        /**
         * @type { ?date } Input date. If isPastInput is true, date is ignored.
         */
        this.date = null;
        /**
         * @type { ?string } Body temp (xx.y)
         */
        this.bodyTemp = null;
        /**
         * @type { ?string } Remarks
         */
        this.remarks = null;
        /**
         * @type { ?boolean } There is no mistake in the contents.
         */
        this.accept = null;
        /**
         * @type { Array.<SetQuestionnaire> } List of questionnaire. 
         */
        this.items = null;
        /**
         * @type { ?boolean } Update heat stroke. Default is false.
         */
        this.updateHeatStroke = null;
        /**
         * @type { ?number } Heat stroke question tiredness * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionTiredness = null;
        /**
         * @type { ?number } Heat stroke question appetite * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionAppetite = null;
        /**
         * @type { ?number } Heat stroke question sleep * 1: Very bad / 非常に悪い * 2: Bad / 悪い * 3: Usually / 普通 * 4: Good / 良い * 5: Very good / 非常に良い 
         */
        this.heatStrokeQuestionSleep = null;
        /**
         * @type { ?number } Heat stroke question sweat * 1: Very good / 非常に良い * 2: Good / 良い * 3: Usually / 普通 * 4: Bad / 悪い * 5: Very bad / 非常に悪い 
         */
        this.heatStrokeQuestionSweat = null;
        /**
         * @type { ?number } Pee color * 1: Light / うすい * 3: Usually / 普通 * 5: Dark / 濃い * 7: Very dark / とても濃い 
         */
        this.peeColor = null;
        /**
         * @type { ?number } Ever had heat stroke * 1: Yes / はい * 2: No　/ いいえ 
         */
        this.everHadHeatStroke = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetQuestionnaireSheet }
     */
    static createFromJson (jsonObject) {
        const obj = new SetQuestionnaireSheet();
        if (typeof(jsonObject.isPastInput) !== "undefined") {
            obj.isPastInput = jsonObject.isPastInput;
        }
        if (typeof(jsonObject.date) !== "undefined") {
            obj.date = jsonObject.date;
        }
        if (typeof(jsonObject.bodyTemp) !== "undefined") {
            obj.bodyTemp = jsonObject.bodyTemp;
        }
        if (typeof(jsonObject.remarks) !== "undefined") {
            obj.remarks = jsonObject.remarks;
        }
        if (typeof(jsonObject.accept) !== "undefined") {
            obj.accept = jsonObject.accept;
        }
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = SetQuestionnaire.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        if (typeof(jsonObject.updateHeatStroke) !== "undefined") {
            obj.updateHeatStroke = jsonObject.updateHeatStroke;
        }
        if (typeof(jsonObject.heatStrokeQuestionTiredness) !== "undefined") {
            obj.heatStrokeQuestionTiredness = jsonObject.heatStrokeQuestionTiredness;
        }
        if (typeof(jsonObject.heatStrokeQuestionAppetite) !== "undefined") {
            obj.heatStrokeQuestionAppetite = jsonObject.heatStrokeQuestionAppetite;
        }
        if (typeof(jsonObject.heatStrokeQuestionSleep) !== "undefined") {
            obj.heatStrokeQuestionSleep = jsonObject.heatStrokeQuestionSleep;
        }
        if (typeof(jsonObject.heatStrokeQuestionSweat) !== "undefined") {
            obj.heatStrokeQuestionSweat = jsonObject.heatStrokeQuestionSweat;
        }
        if (typeof(jsonObject.peeColor) !== "undefined") {
            obj.peeColor = jsonObject.peeColor;
        }
        if (typeof(jsonObject.everHadHeatStroke) !== "undefined") {
            obj.everHadHeatStroke = jsonObject.everHadHeatStroke;
        }
        return obj;
  }
}

/*
var request_data = new SetQuestionnaireSheet()
request_data.isPastInput = null;
request_data.date = null;
request_data.bodyTemp = null;
request_data.remarks = null;
request_data.accept = null;
request_data.items = null;
request_data.updateHeatStroke = null;
request_data.heatStrokeQuestionTiredness = null;
request_data.heatStrokeQuestionAppetite = null;
request_data.heatStrokeQuestionSleep = null;
request_data.heatStrokeQuestionSweat = null;
request_data.peeColor = null;
request_data.everHadHeatStroke = null;
*/

/**
 * Set IP User
 * @constructor
 */
export class SetRegister {
    constructor() {
        /**
         * @type { string } Key given to the URL of the registration form
         */
        this.tournamentKey = null;
        /**
         * @type { string } Email
         */
        this.email = null;
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { date } Birthday
         */
        this.birthday = null;
        /**
         * @type { number } Role ID
         */
        this.userRoleId = null;
        /**
         * @type { ?string } Organization
         */
        this.organization = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetRegister }
     */
    static createFromJson (jsonObject) {
        const obj = new SetRegister();
        if (typeof(jsonObject.tournamentKey) !== "undefined") {
            obj.tournamentKey = jsonObject.tournamentKey;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.userRoleId) !== "undefined") {
            obj.userRoleId = jsonObject.userRoleId;
        }
        if (typeof(jsonObject.organization) !== "undefined") {
            obj.organization = jsonObject.organization;
        }
        return obj;
  }
}

/*
var request_data = new SetRegister()
request_data.tournamentKey = null;
request_data.email = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.birthday = null;
request_data.userRoleId = null;
request_data.organization = null;
*/

/**
 * Set remind mail object
 * @constructor
 */
export class SetRemindMail {
    constructor() {
        /**
         * @type { boolean } Remind mail. 
         */
        this.remindMail = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetRemindMail }
     */
    static createFromJson (jsonObject) {
        const obj = new SetRemindMail();
        if (typeof(jsonObject.remindMail) !== "undefined") {
            obj.remindMail = jsonObject.remindMail;
        }
        return obj;
  }
}

/*
var request_data = new SetRemindMail()
request_data.remindMail = null;
*/

/**
 * Set reset password
 * @constructor
 */
export class SetResetPassword {
    constructor() {
        /**
         * @type { string } Email
         */
        this.email = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetResetPassword }
     */
    static createFromJson (jsonObject) {
        const obj = new SetResetPassword();
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        return obj;
  }
}

/*
var request_data = new SetResetPassword()
request_data.email = null;
*/

/**
 * Set reset password finish
 * @constructor
 */
export class SetResetPasswordFinish {
    constructor() {
        /**
         * @type { string } New Password
         */
        this.newPassword = null;
        /**
         * @type { string } New Password Confirm
         */
        this.newPasswordConfirm = null;
        /**
         * @type { string } Reset password token
         */
        this.token = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetResetPasswordFinish }
     */
    static createFromJson (jsonObject) {
        const obj = new SetResetPasswordFinish();
        if (typeof(jsonObject.newPassword) !== "undefined") {
            obj.newPassword = jsonObject.newPassword;
        }
        if (typeof(jsonObject.newPasswordConfirm) !== "undefined") {
            obj.newPasswordConfirm = jsonObject.newPasswordConfirm;
        }
        if (typeof(jsonObject.token) !== "undefined") {
            obj.token = jsonObject.token;
        }
        return obj;
  }
}

/*
var request_data = new SetResetPasswordFinish()
request_data.newPassword = null;
request_data.newPasswordConfirm = null;
request_data.token = null;
*/

/**
 * Set Update User Account object. Do not update null params.
 * @constructor
 */
export class SetUpdateUser {
    constructor() {
        /**
         * @type { boolean } If true for the first update.
         */
        this.initialUpdate = null;
        /**
         * @type { ?string } Authentication password. password / email / firstName/ lastName / birthday is included, and initialUpdate is false. Null if not needed. 
         */
        this.authenticationPassword = null;
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { boolean } Update flag First name
         */
        this.firstNameUpdate = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { boolean } Update flag Last name
         */
        this.lastNameUpdate = null;
        /**
         * @type { ?string } Password
         */
        this.password = null;
        /**
         * @type { ?string } Password confirm
         */
        this.passwordConfirm = null;
        /**
         * @type { boolean } Update flag Password
         */
        this.passwordUpdate = null;
        /**
         * @type { ?date } Birthday If initialUpdate is true, it will not be updated. 
         */
        this.birthday = null;
        /**
         * @type { boolean } Update flag birthday
         */
        this.birthdayUpdate = null;
        /**
         * @type { ?string } E-mail
         */
        this.email = null;
        /**
         * @type { boolean } Update flag E-mail
         */
        this.emailUpdate = null;
        /**
         * @type { ?number } Gender | * Male = 1 * Female = 2 * Other = 3
         */
        this.gender = null;
        /**
         * @type { boolean } Update flag gender
         */
        this.genderUpdate = null;
        /**
         * @type { ?number } Nationality ID
         */
        this.nationalityId = null;
        /**
         * @type { boolean } Update flag Nationality ID
         */
        this.nationalityIdUpdate = null;
        /**
         * @type { ?string } Address
         */
        this.address = null;
        /**
         * @type { boolean } Update flag Address
         */
        this.addressUpdate = null;
        /**
         * @type { ?string } Telephone No
         */
        this.phoneNo = null;
        /**
         * @type { boolean } Update flag Telephone No
         */
        this.phoneNoUpdate = null;
        /**
         * @type { ?boolean } Has vaccination
         */
        this.hasCoronaVaccination = null;
        /**
         * @type { ?date } The day of the last vaccination
         */
        this.latestCoronaVaccinationDate = null;
        /**
         * @type { ?number } Number of vaccinations
         */
        this.coronaVaccinationCount = null;
        /**
         * @type { boolean } Update flag vaccination
         */
        this.coronaVaccinationUpdate = null;
        /**
         * @type { ?number } Results of the last PCR test * 0: No Check * -1: Negative * 1: Positive 
         */
        this.latestPcrTestResult = null;
        /**
         * @type { ?date } Date of the last PCR test
         */
        this.latestPcrTestDate = null;
        /**
         * @type { boolean } Update flag PCR test
         */
        this.latestPcrTestUpdate = null;
        /**
         * @type { ?boolean } Anemia/貧血
         */
        this.anemia = null;
        /**
         * @type { ?boolean } Asthma/ぜんそく
         */
        this.asthma = null;
        /**
         * @type { ?boolean } Heart disease/心臓病
         */
        this.heartDisease = null;
        /**
         * @type { ?boolean } Kidney disease/腎臓病
         */
        this.kidneyDisease = null;
        /**
         * @type { ?boolean } Epilepsy/てんかん
         */
        this.epilepsy = null;
        /**
         * @type { ?boolean } High blood pressure/高血圧
         */
        this.highBloodPressure = null;
        /**
         * @type { ?boolean } Diabetes/糖尿病
         */
        this.diabetes = null;
        /**
         * @type { ?boolean } Others/その他
         */
        this.other = null;
        /**
         * @type { ?string } Other reasons. If other is true, it is required.
         */
        this.otherText = null;
        /**
         * @type { ?boolean } Update flag medical history. Default is false
         */
        this.medicalHistoryUpdate = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { SetUpdateUser }
     */
    static createFromJson (jsonObject) {
        const obj = new SetUpdateUser();
        if (typeof(jsonObject.initialUpdate) !== "undefined") {
            obj.initialUpdate = jsonObject.initialUpdate;
        }
        if (typeof(jsonObject.authenticationPassword) !== "undefined") {
            obj.authenticationPassword = jsonObject.authenticationPassword;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.firstNameUpdate) !== "undefined") {
            obj.firstNameUpdate = jsonObject.firstNameUpdate;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.lastNameUpdate) !== "undefined") {
            obj.lastNameUpdate = jsonObject.lastNameUpdate;
        }
        if (typeof(jsonObject.password) !== "undefined") {
            obj.password = jsonObject.password;
        }
        if (typeof(jsonObject.passwordConfirm) !== "undefined") {
            obj.passwordConfirm = jsonObject.passwordConfirm;
        }
        if (typeof(jsonObject.passwordUpdate) !== "undefined") {
            obj.passwordUpdate = jsonObject.passwordUpdate;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.birthdayUpdate) !== "undefined") {
            obj.birthdayUpdate = jsonObject.birthdayUpdate;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.emailUpdate) !== "undefined") {
            obj.emailUpdate = jsonObject.emailUpdate;
        }
        if (typeof(jsonObject.gender) !== "undefined") {
            obj.gender = jsonObject.gender;
        }
        if (typeof(jsonObject.genderUpdate) !== "undefined") {
            obj.genderUpdate = jsonObject.genderUpdate;
        }
        if (typeof(jsonObject.nationalityId) !== "undefined") {
            obj.nationalityId = jsonObject.nationalityId;
        }
        if (typeof(jsonObject.nationalityIdUpdate) !== "undefined") {
            obj.nationalityIdUpdate = jsonObject.nationalityIdUpdate;
        }
        if (typeof(jsonObject.address) !== "undefined") {
            obj.address = jsonObject.address;
        }
        if (typeof(jsonObject.addressUpdate) !== "undefined") {
            obj.addressUpdate = jsonObject.addressUpdate;
        }
        if (typeof(jsonObject.phoneNo) !== "undefined") {
            obj.phoneNo = jsonObject.phoneNo;
        }
        if (typeof(jsonObject.phoneNoUpdate) !== "undefined") {
            obj.phoneNoUpdate = jsonObject.phoneNoUpdate;
        }
        if (typeof(jsonObject.hasCoronaVaccination) !== "undefined") {
            obj.hasCoronaVaccination = jsonObject.hasCoronaVaccination;
        }
        if (typeof(jsonObject.latestCoronaVaccinationDate) !== "undefined") {
            obj.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate;
        }
        if (typeof(jsonObject.coronaVaccinationCount) !== "undefined") {
            obj.coronaVaccinationCount = jsonObject.coronaVaccinationCount;
        }
        if (typeof(jsonObject.coronaVaccinationUpdate) !== "undefined") {
            obj.coronaVaccinationUpdate = jsonObject.coronaVaccinationUpdate;
        }
        if (typeof(jsonObject.latestPcrTestResult) !== "undefined") {
            obj.latestPcrTestResult = jsonObject.latestPcrTestResult;
        }
        if (typeof(jsonObject.latestPcrTestDate) !== "undefined") {
            obj.latestPcrTestDate = jsonObject.latestPcrTestDate;
        }
        if (typeof(jsonObject.latestPcrTestUpdate) !== "undefined") {
            obj.latestPcrTestUpdate = jsonObject.latestPcrTestUpdate;
        }
        if (typeof(jsonObject.anemia) !== "undefined") {
            obj.anemia = jsonObject.anemia;
        }
        if (typeof(jsonObject.asthma) !== "undefined") {
            obj.asthma = jsonObject.asthma;
        }
        if (typeof(jsonObject.heartDisease) !== "undefined") {
            obj.heartDisease = jsonObject.heartDisease;
        }
        if (typeof(jsonObject.kidneyDisease) !== "undefined") {
            obj.kidneyDisease = jsonObject.kidneyDisease;
        }
        if (typeof(jsonObject.epilepsy) !== "undefined") {
            obj.epilepsy = jsonObject.epilepsy;
        }
        if (typeof(jsonObject.highBloodPressure) !== "undefined") {
            obj.highBloodPressure = jsonObject.highBloodPressure;
        }
        if (typeof(jsonObject.diabetes) !== "undefined") {
            obj.diabetes = jsonObject.diabetes;
        }
        if (typeof(jsonObject.other) !== "undefined") {
            obj.other = jsonObject.other;
        }
        if (typeof(jsonObject.otherText) !== "undefined") {
            obj.otherText = jsonObject.otherText;
        }
        if (typeof(jsonObject.medicalHistoryUpdate) !== "undefined") {
            obj.medicalHistoryUpdate = jsonObject.medicalHistoryUpdate;
        }
        return obj;
  }
}

/*
var request_data = new SetUpdateUser()
request_data.initialUpdate = null;
request_data.authenticationPassword = null;
request_data.firstName = null;
request_data.firstNameUpdate = null;
request_data.lastName = null;
request_data.lastNameUpdate = null;
request_data.password = null;
request_data.passwordConfirm = null;
request_data.passwordUpdate = null;
request_data.birthday = null;
request_data.birthdayUpdate = null;
request_data.email = null;
request_data.emailUpdate = null;
request_data.gender = null;
request_data.genderUpdate = null;
request_data.nationalityId = null;
request_data.nationalityIdUpdate = null;
request_data.address = null;
request_data.addressUpdate = null;
request_data.phoneNo = null;
request_data.phoneNoUpdate = null;
request_data.hasCoronaVaccination = null;
request_data.latestCoronaVaccinationDate = null;
request_data.coronaVaccinationCount = null;
request_data.coronaVaccinationUpdate = null;
request_data.latestPcrTestResult = null;
request_data.latestPcrTestDate = null;
request_data.latestPcrTestUpdate = null;
request_data.anemia = null;
request_data.asthma = null;
request_data.heartDisease = null;
request_data.kidneyDisease = null;
request_data.epilepsy = null;
request_data.highBloodPressure = null;
request_data.diabetes = null;
request_data.other = null;
request_data.otherText = null;
request_data.medicalHistoryUpdate = null;
*/

/**
 * Tournament list object
 * @constructor
 */
export class Tournament {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { date } Start at
         */
        this.startAt = null;
        /**
         * @type { date } End at
         */
        this.endAt = null;
        /**
         * @type { date } Input start at
         */
        this.inputStartAt = null;
        /**
         * @type { date } Input end at
         */
        this.inputEndAt = null;
        /**
         * @type { string } User role
         */
        this.userRole = null;
        /**
         * @type { number } * Coming soon / 近日開始 = 1 * During the duration / 期間中 = 2 * Ended / 終了 = 3 
         */
        this.status = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { Tournament }
     */
    static createFromJson (jsonObject) {
        const obj = new Tournament();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.startAt) !== "undefined") {
            obj.startAt = jsonObject.startAt;
        }
        if (typeof(jsonObject.endAt) !== "undefined") {
            obj.endAt = jsonObject.endAt;
        }
        if (typeof(jsonObject.inputStartAt) !== "undefined") {
            obj.inputStartAt = jsonObject.inputStartAt;
        }
        if (typeof(jsonObject.inputEndAt) !== "undefined") {
            obj.inputEndAt = jsonObject.inputEndAt;
        }
        if (typeof(jsonObject.userRole) !== "undefined") {
            obj.userRole = jsonObject.userRole;
        }
        if (typeof(jsonObject.status) !== "undefined") {
            obj.status = jsonObject.status;
        }
        return obj;
  }
}

/*
var request_data = new Tournament()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.startAt = null;
request_data.endAt = null;
request_data.inputStartAt = null;
request_data.inputEndAt = null;
request_data.userRole = null;
request_data.status = null;
*/

/**
 * Tournament detail object
 * @constructor
 */
export class TournamentDetail {
    constructor() {
        /**
         * @type { number } Tournament ID
         */
        this.tournamentId = null;
        /**
         * @type { string } Tournament Name
         */
        this.tournamentName = null;
        /**
         * @type { date } Start at
         */
        this.startAt = null;
        /**
         * @type { date } End at
         */
        this.endAt = null;
        /**
         * @type { date } Input start at
         */
        this.inputStartAt = null;
        /**
         * @type { date } Input end at
         */
        this.inputEndAt = null;
        /**
         * @type { string } User role
         */
        this.userRole = null;
        /**
         * @type { number } * Coming soon / 近日開始 = 1 * During the duration / 期間中 = 2 * Ended / 終了 = 3 
         */
        this.status = null;
        /**
         * @type { ?boolean } Remind mail
         */
        this.remindMail = null;
        /**
         * @type { ?string } Host prefecture
         */
        this.hostPrefecture = null;
        /**
         * @type { ?string } Host organization
         */
        this.hostOrganization = null;
        /**
         * @type { ?string } Inquiry phone no
         */
        this.inquiryPhoneNo = null;
        /**
         * @type { ?string } Inquiry email
         */
        this.inquiryEmail = null;
        /**
         * @type { ?string } Tournament admin email
         */
        this.tournamentAdminEmail = null;
        /**
         * @type { ?number } Previous tournament ID
         */
        this.prevTournamentId = null;
        /**
         * @type { ?number } Next tournament ID
         */
        this.nextTournamentId = null;
        /**
         * @type { ?string } Previous tournament name
         */
        this.prevTournamentName = null;
        /**
         * @type { ?string } Next tournament name
         */
        this.nextTournamentName = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { TournamentDetail }
     */
    static createFromJson (jsonObject) {
        const obj = new TournamentDetail();
        if (typeof(jsonObject.tournamentId) !== "undefined") {
            obj.tournamentId = jsonObject.tournamentId;
        }
        if (typeof(jsonObject.tournamentName) !== "undefined") {
            obj.tournamentName = jsonObject.tournamentName;
        }
        if (typeof(jsonObject.startAt) !== "undefined") {
            obj.startAt = jsonObject.startAt;
        }
        if (typeof(jsonObject.endAt) !== "undefined") {
            obj.endAt = jsonObject.endAt;
        }
        if (typeof(jsonObject.inputStartAt) !== "undefined") {
            obj.inputStartAt = jsonObject.inputStartAt;
        }
        if (typeof(jsonObject.inputEndAt) !== "undefined") {
            obj.inputEndAt = jsonObject.inputEndAt;
        }
        if (typeof(jsonObject.userRole) !== "undefined") {
            obj.userRole = jsonObject.userRole;
        }
        if (typeof(jsonObject.status) !== "undefined") {
            obj.status = jsonObject.status;
        }
        if (typeof(jsonObject.remindMail) !== "undefined") {
            obj.remindMail = jsonObject.remindMail;
        }
        if (typeof(jsonObject.hostPrefecture) !== "undefined") {
            obj.hostPrefecture = jsonObject.hostPrefecture;
        }
        if (typeof(jsonObject.hostOrganization) !== "undefined") {
            obj.hostOrganization = jsonObject.hostOrganization;
        }
        if (typeof(jsonObject.inquiryPhoneNo) !== "undefined") {
            obj.inquiryPhoneNo = jsonObject.inquiryPhoneNo;
        }
        if (typeof(jsonObject.inquiryEmail) !== "undefined") {
            obj.inquiryEmail = jsonObject.inquiryEmail;
        }
        if (typeof(jsonObject.tournamentAdminEmail) !== "undefined") {
            obj.tournamentAdminEmail = jsonObject.tournamentAdminEmail;
        }
        if (typeof(jsonObject.prevTournamentId) !== "undefined") {
            obj.prevTournamentId = jsonObject.prevTournamentId;
        }
        if (typeof(jsonObject.nextTournamentId) !== "undefined") {
            obj.nextTournamentId = jsonObject.nextTournamentId;
        }
        if (typeof(jsonObject.prevTournamentName) !== "undefined") {
            obj.prevTournamentName = jsonObject.prevTournamentName;
        }
        if (typeof(jsonObject.nextTournamentName) !== "undefined") {
            obj.nextTournamentName = jsonObject.nextTournamentName;
        }
        return obj;
  }
}

/*
var request_data = new TournamentDetail()
request_data.tournamentId = null;
request_data.tournamentName = null;
request_data.startAt = null;
request_data.endAt = null;
request_data.inputStartAt = null;
request_data.inputEndAt = null;
request_data.userRole = null;
request_data.status = null;
request_data.remindMail = null;
request_data.hostPrefecture = null;
request_data.hostOrganization = null;
request_data.inquiryPhoneNo = null;
request_data.inquiryEmail = null;
request_data.tournamentAdminEmail = null;
request_data.prevTournamentId = null;
request_data.nextTournamentId = null;
request_data.prevTournamentName = null;
request_data.nextTournamentName = null;
*/

/**
 * Tournament list object
 * @constructor
 */
export class TournamentItems {
    constructor() {
        /**
         * @type { Array.<Tournament> } List of tournament. 
         */
        this.items = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { TournamentItems }
     */
    static createFromJson (jsonObject) {
        const obj = new TournamentItems();
        if (typeof(jsonObject.items) !== "undefined") {
            if (jsonObject.items !== null) {
                obj.items = [];
                for (let i = 0; i < jsonObject.items.length; i++) {
                    const item = Tournament.createFromJson(jsonObject.items[i]);
                    obj.items.push(item);
                }
            } else {
                obj.items = null;
            }
        }
        return obj;
  }
}

/*
var request_data = new TournamentItems()
request_data.items = null;
*/

/**
 * User Account object
 * @constructor
 */
export class User {
    constructor() {
        /**
         * @type { string } User identification key
         */
        this.userId = null;
        /**
         * @type { string } Email
         */
        this.email = null;
        /**
         * @type { boolean } If true, you are using the initial password.
         */
        this.isInitialPassword = null;
        /**
         * @type { boolean } If true, initial information is not registered.
         */
        this.requireUserInfo = null;
        /**
         * @type { ?string } First name
         */
        this.firstName = null;
        /**
         * @type { ?string } Last name
         */
        this.lastName = null;
        /**
         * @type { ?date } Birthday
         */
        this.birthday = null;
        /**
         * @type { ?number } Gender | * Male = 1 * Female = 2 * Other = 3
         */
        this.gender = null;
        /**
         * @type { ?number } Nationality ID
         */
        this.nationalityId = null;
        /**
         * @type { ?string } Nationality Name
         */
        this.nationalityName = null;
        /**
         * @type { ?string } Address
         */
        this.address = null;
        /**
         * @type { ?string } Telephone No
         */
        this.phoneNo = null;
        /**
         * @type { ?boolean } Has vaccination
         */
        this.hasCoronaVaccination = null;
        /**
         * @type { ?date } The day of the last vaccination
         */
        this.latestCoronaVaccinationDate = null;
        /**
         * @type { ?number } Number of vaccinations
         */
        this.coronaVaccinationCount = null;
        /**
         * @type { ?number } Results of the last PCR test * 0: No Check * -1: Negative * 1: Positive 
         */
        this.latestPcrTestResult = null;
        /**
         * @type { ?date } Date of the last PCR test
         */
        this.latestPcrTestDate = null;
        /**
         * @type { boolean } Is it JTA User?
         */
        this.isJTA = null;
        /**
         * @type { boolean } Anemia/貧血
         */
        this.anemia = null;
        /**
         * @type { boolean } Asthma/ぜんそく
         */
        this.asthma = null;
        /**
         * @type { boolean } Heart disease/心臓病
         */
        this.heartDisease = null;
        /**
         * @type { boolean } Kidney disease/腎臓病
         */
        this.kidneyDisease = null;
        /**
         * @type { boolean } Epilepsy/てんかん
         */
        this.epilepsy = null;
        /**
         * @type { boolean } High blood pressure/高血圧
         */
        this.highBloodPressure = null;
        /**
         * @type { boolean } Diabetes/糖尿病
         */
        this.diabetes = null;
        /**
         * @type { boolean } Other/その他
         */
        this.other = null;
        /**
         * @type { ?string } Other reasons. If other is true, it is required.
         */
        this.otherText = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { User }
     */
    static createFromJson (jsonObject) {
        const obj = new User();
        if (typeof(jsonObject.userId) !== "undefined") {
            obj.userId = jsonObject.userId;
        }
        if (typeof(jsonObject.email) !== "undefined") {
            obj.email = jsonObject.email;
        }
        if (typeof(jsonObject.isInitialPassword) !== "undefined") {
            obj.isInitialPassword = jsonObject.isInitialPassword;
        }
        if (typeof(jsonObject.requireUserInfo) !== "undefined") {
            obj.requireUserInfo = jsonObject.requireUserInfo;
        }
        if (typeof(jsonObject.firstName) !== "undefined") {
            obj.firstName = jsonObject.firstName;
        }
        if (typeof(jsonObject.lastName) !== "undefined") {
            obj.lastName = jsonObject.lastName;
        }
        if (typeof(jsonObject.birthday) !== "undefined") {
            obj.birthday = jsonObject.birthday;
        }
        if (typeof(jsonObject.gender) !== "undefined") {
            obj.gender = jsonObject.gender;
        }
        if (typeof(jsonObject.nationalityId) !== "undefined") {
            obj.nationalityId = jsonObject.nationalityId;
        }
        if (typeof(jsonObject.nationalityName) !== "undefined") {
            obj.nationalityName = jsonObject.nationalityName;
        }
        if (typeof(jsonObject.address) !== "undefined") {
            obj.address = jsonObject.address;
        }
        if (typeof(jsonObject.phoneNo) !== "undefined") {
            obj.phoneNo = jsonObject.phoneNo;
        }
        if (typeof(jsonObject.hasCoronaVaccination) !== "undefined") {
            obj.hasCoronaVaccination = jsonObject.hasCoronaVaccination;
        }
        if (typeof(jsonObject.latestCoronaVaccinationDate) !== "undefined") {
            obj.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate;
        }
        if (typeof(jsonObject.coronaVaccinationCount) !== "undefined") {
            obj.coronaVaccinationCount = jsonObject.coronaVaccinationCount;
        }
        if (typeof(jsonObject.latestPcrTestResult) !== "undefined") {
            obj.latestPcrTestResult = jsonObject.latestPcrTestResult;
        }
        if (typeof(jsonObject.latestPcrTestDate) !== "undefined") {
            obj.latestPcrTestDate = jsonObject.latestPcrTestDate;
        }
        if (typeof(jsonObject.isJTA) !== "undefined") {
            obj.isJTA = jsonObject.isJTA;
        }
        if (typeof(jsonObject.anemia) !== "undefined") {
            obj.anemia = jsonObject.anemia;
        }
        if (typeof(jsonObject.asthma) !== "undefined") {
            obj.asthma = jsonObject.asthma;
        }
        if (typeof(jsonObject.heartDisease) !== "undefined") {
            obj.heartDisease = jsonObject.heartDisease;
        }
        if (typeof(jsonObject.kidneyDisease) !== "undefined") {
            obj.kidneyDisease = jsonObject.kidneyDisease;
        }
        if (typeof(jsonObject.epilepsy) !== "undefined") {
            obj.epilepsy = jsonObject.epilepsy;
        }
        if (typeof(jsonObject.highBloodPressure) !== "undefined") {
            obj.highBloodPressure = jsonObject.highBloodPressure;
        }
        if (typeof(jsonObject.diabetes) !== "undefined") {
            obj.diabetes = jsonObject.diabetes;
        }
        if (typeof(jsonObject.other) !== "undefined") {
            obj.other = jsonObject.other;
        }
        if (typeof(jsonObject.otherText) !== "undefined") {
            obj.otherText = jsonObject.otherText;
        }
        return obj;
  }
}

/*
var request_data = new User()
request_data.userId = null;
request_data.email = null;
request_data.isInitialPassword = null;
request_data.requireUserInfo = null;
request_data.firstName = null;
request_data.lastName = null;
request_data.birthday = null;
request_data.gender = null;
request_data.nationalityId = null;
request_data.nationalityName = null;
request_data.address = null;
request_data.phoneNo = null;
request_data.hasCoronaVaccination = null;
request_data.latestCoronaVaccinationDate = null;
request_data.coronaVaccinationCount = null;
request_data.latestPcrTestResult = null;
request_data.latestPcrTestDate = null;
request_data.isJTA = null;
request_data.anemia = null;
request_data.asthma = null;
request_data.heartDisease = null;
request_data.kidneyDisease = null;
request_data.epilepsy = null;
request_data.highBloodPressure = null;
request_data.diabetes = null;
request_data.other = null;
request_data.otherText = null;
*/

/**
 * ValidationError object. 
 * @constructor
 */
export class ValidationError {
    constructor() {
        /**
         * @type { string } Key name with error
         */
        this.name = null;
        /**
         * @type { string } Error message
         */
        this.message = null;
    }

    /**
     * JSONからモデルクラスを作成。
     * @param { Object } jsonObject
     * @returns { ValidationError }
     */
    static createFromJson (jsonObject) {
        const obj = new ValidationError();
        if (typeof(jsonObject.name) !== "undefined") {
            obj.name = jsonObject.name;
        }
        if (typeof(jsonObject.message) !== "undefined") {
            obj.message = jsonObject.message;
        }
        return obj;
  }
}

/*
var request_data = new ValidationError()
request_data.name = null;
request_data.message = null;
*/


var mergeObject = function (obj1, obj2) {
    if (obj1 == null) {
        obj1 = {};
    }
    if (obj2 == null) {
        obj2 = {};
    }
    for (var attrname in obj2) {
        if (obj2.hasOwnProperty(attrname)) {
            obj1[attrname] = obj2[attrname];
        }
    }
    return obj1;
};
