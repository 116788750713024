<template>
  <div v-if="loading" class="ml-4 loader">
    <Loader></Loader>
  </div>
  <div v-else id="layout-wrapper" class="layout-wrapper">
    <NavBar v-if="$route.name !== 'Login'
      || $route.name !== 'LoginWithDob'
      || $route.name !== 'InitialLoginFirst'
      || $route.name !== 'InitialLoginSecond'
      || $route.name !== 'InitialLoginThird'
      || $route.name !== 'ResetPassword'
      || $route.name !== 'ResetPasswordEmailSent'
      || $route.name !== 'ChangePassword'"></NavBar>
    <div v-if="$route.name" class="container-fluid">
      <div class="row">
        <main id="main" :class="'main-' + $route.name.toLowerCase()" class="main col ml-sm-auto" role="main">
          <div v-if="$route.name" :class="$route.name.toLowerCase()" class="content">
            <slot></slot>
          </div>
        </main>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Footer from "./Footer"

import Loader from '../loaders/Loader'

export default {
  name: 'BaseLayout',
  components: {NavBar, Footer, Loader},
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.loading = false
  },
  methods: {}

}

</script>

<style lang="scss" scoped>
.layout-wrapper {

}

.main {
  background-color: #FFFFFF;
  min-height: calc(100vh - 134px);

  padding-right: 0;
  padding-left: 0;
}

.content {
  position: relative;
  top: 40px;

  &.userrecord,
  &.tournamentinfo,
  &.userrecorddetail,
  &.deleteaccount,
  &.editmedicalhistory {
    top: 16px;
  }

  &.qr {
    top: 70px;
  }

  &.accountsettings {
    top: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .content {
    position: relative;
    top: 0;

    &.userrecord,
    &.tournamentinfo,
    &.userrecorddetail,
    &.qr,
    &.home,
    &.deleteaccount,
    &.editmedicalhistory {
      top: 0;
    }

    &.privacypolicy, &.cookiepolicy, &.credit, &.contact, &.termsofservice {
      top: 40px;
    }
  }
}

</style>
