import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import Common from "./assets/jsipclient/common";

Vue.use(VueRouter)

const routes = [
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      title: ''
    },
    component: () => import( /* webpackChunkName: "js/dashboard" */ './views/Dashboard.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'ログイン',
      goHome: true,
    },
    component: () => import( /* webpackChunkName: "js/login" */ './views/LoginWithDob.vue') // TODO: use Login.vue
  },
  {
    path: '/loginwithdob',
    name: 'LoginWithDob',
    meta: {
      title: 'ログイン',
      goHome: true,
    },
    component: () => import( /* webpackChunkName: "js/loginwithdob" */ './views/LoginWithDob.vue')
  },
  {
    path: '/firsttime',
    name: 'InitialLoginFirst',
    meta: {
      title: '初回設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/firsttime" */ './views/InitialLoginFirst.vue')
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    meta: {
      title: 'パスワードリセット'
    },
    component: () => import( /* webpackChunkName: "js/resetpassword" */ './views/ResetPassword.vue')
  },
  {
    path: '/resetpasswordemailsent',
    name: 'ResetPasswordEmailSent',
    meta: {
      title: 'パスワードリセット'
    },
    component: () => import( /* webpackChunkName: "js/resetpasswordemailsent" */ './views/ResetPasswordEmailSent.vue')
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    meta: {
      title: 'パスワード変更'
    },
    component: () => import( /* webpackChunkName: "js/changepassword" */ './views/ChangePassword.vue')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: 'ホーム',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/home" */ './views/Home.vue')
  },
  {
    path: '/qr',
    name: 'Qr',
    meta: {
      title: 'QR表示',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/qr" */ './views/Qr.vue')
  },
  {
    path: '/tournamentinfo/:id',
    name: 'TournamentInfo',
    meta: {
      title: 'ホーム',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/tournamentinfo" */ './views/TournamentInfo.vue')
  },
  {
    path: '/inputcondition',
    name: 'InputHealthCondition',
    meta: {
      title: '健康状態入力',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/inputcondition" */ './views/InputHealthCondition.vue')
  },
  {
    path: '/record',
    name: 'Record',
    meta: {
      title: '健康記録参照',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/record" */ './views/Record.vue')
  },
  {
    path: '/record/:tournamentId/list',
    name: 'UserRecord',
    meta: {
      title: '健康記録参照',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/user-record" */ './views/UserRecord.vue')
  },
  {
    path: '/record/:tournamentId/detail',
    name: 'UserRecordDetail',
    meta: {
      title: '健康記録参照',
      requiresAuth: true
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/user-record-detail" */ './views/UserRecordDetail.vue')
  },
  {
    path: '/account',
    name: 'AccountSettings',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/account" */ './views/AccountSettings.vue')
  },
  {
    path: '/editpasswordrequired',
    name: 'EditPasswordRequired',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editpasswordrequired" */ './views/EditPasswordRequired.vue')
  },
  {
    path: '/deleteaccount',
    name: 'DeleteAccount',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/deleteaccount" */ './views/DeleteAccount.vue')
  },

  {
    path: '/pcrtestchange',
    name: 'PcrTestChange',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/PcrTestChange" */ './views/PcrTestChange.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    meta: {
      title: 'プライバシーポリシー',
      requiresAuth: false,
    },
    component: () => import( /* webpackChunkName: "js/privacy" */ './views/PrivacyPolicy.vue')
  },
  {
    path: '/cookie',
    name: 'CookiePolicy',
    meta: {
      title: 'Cookieポリシー',
      requiresAuth: false,
    },
    component: () => import( /* webpackChunkName: "js/cookie" */ './views/CookiePolicy.vue')
  },
  {
    path: '/termsofservice',
    name: 'TermsOfService',
    meta: {
      title: '利用規約',
      requiresAuth: false,
    },
    component: () => import( /* webpackChunkName: "js/termsofservice" */ './views/TermsOfService.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'お問い合わせ',
      requiresAuth: false,
    },
    component: () => import( /* webpackChunkName: "js/contact" */ './views/Contact.vue')
  },
  {
    path: '/credit',
    name: 'Credit',
    meta: {
      title: 'HeaLoとは',
      requiresAuth: false,
    },
    component: () => import( /* webpackChunkName: "js/credit" */ './views/Credit.vue')
  },
  {
    path: '/register/:key',
    name: 'RegisterUser',
    meta: {
      title: 'HeaLoとは',
      requiresAuth: false,
    },
    component: () => import( /* webpackChunkName: "js/registeruser" */ './views/RegisterUser.vue')
  },
  {
    path: '/editname',
    name: 'EditName',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editname" */ './views/EditName.vue')
  },
  {
    path: '/editbirthday',
    name: 'EditBirthday',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editbirthday" */ './views/EditBirthday.vue')
  },
  {
    path: '/editsex',
    name: 'EditSex',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editsex" */ './views/EditSex.vue')
  },
  {
    path: '/editcountry',
    name: 'EditCountry',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editcountry" */ './views/EditCountry.vue')
  },
  {
    path: '/editmailaddress',
    name: 'EditMailAddress',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editmailaddress" */ './views/EditMailAddress.vue')
  },
  {
    path: '/editaddress',
    name: 'EditAddress',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editaddress" */ './views/EditAddress.vue')
  },
  {
    path: '/editphone',
    name: 'EditPhone',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editphone" */ './views/EditPhone.vue')
  },
  {
    path: '/editmedicalhistory',
    name: 'EditMedicalHistory',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    component: () => import( /* webpackChunkName: "js/editmedicalhistory" */ './views/EditMedicalHistory.vue')
  },
  {
    path: '/vaccinechange',
    name: 'VaccineChange',
    meta: {
      title: 'アカウント設定',
      requiresAuth: true,
    },
    props: true,
    component: () => import( /* webpackChunkName: "js/vaccinechange" */ './views/VaccineChange.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

router.beforeEach(async (to, from, next) => {
  const needLogin = to.matched.some(record => record.meta.requiresAuth)
  const config = store.getters['config']
  const invalidToken = (config.token === null || config.token === "undefined")

  // Check login
  if (needLogin && invalidToken) {
    next({name: 'Login'})
    return
  }

  const goHome = to.matched.some(record => record.meta.goHome)
  if (goHome && !invalidToken) {
    try {
      const user_id = config.userId
      if (user_id) {
        const api = Common.createUserApi(config.token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        if (jsonObject.requireUserInfo === false) {
          if (to.name === "Login" && to.query.t != null) {
            next()
            return
          }
          next({name: 'Home'})
          return
        }
      }
    } catch (error) {
    }
  }

  next()
})

export default router
