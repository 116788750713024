<template>
  <nav class="navbar sticky navbar-expand-lg navbar-light">
    <div class="container-fluid p-l-0">
      <router-link class="navbar-brand" to="/home">
        <img alt="" src="../../assets/images/jta_logo.png">
      </router-link>
      <p class="logo-title mb-0" v-html="$t('app.default.brandName')"></p>
      <button v-if="$route.name !== 'Login'
      && $route.name !== 'LoginWithDob'
      && $route.name !== 'InitialLoginFirst'
      && $route.name !== 'InitialLoginSecond'
      && $route.name !== 'InitialLoginThird'
      && $route.name !== 'ResetPassword'
      && $route.name !== 'ResetPasswordEmailSent'
      && $route.name !== 'ChangePassword'
      && $route.name !== 'PrivacyPolicy'
      && $route.name !== 'CookiePolicy'
      && $route.name !== 'TermsOfService'
      && $route.name !== 'Contact'
      && $route.name !== 'RegisterUser'
      && $route.name !== 'Credit'"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler"
              data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="$route.name !== 'Login'
      && $route.name !== 'LoginWithDob'
      && $route.name !== 'InitialLoginFirst'
      && $route.name !== 'InitialLoginSecond'
      && $route.name !== 'InitialLoginThird'
      && $route.name !== 'ResetPassword'
      && $route.name !== 'ResetPasswordEmailSent'
      && $route.name !== 'ChangePassword'
      && $route.name !== 'PrivacyPolicy'
      && $route.name !== 'CookiePolicy'
      && $route.name !== 'TermsOfService'
      && $route.name !== 'Contact'
      && $route.name !== 'Credit'
      && $route.name !== 'RegisterUser'"
           id="navbarSupportedContent" class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto small-screen">
          <li class="nav-item last-child dropdown">
            <div class="row ">
              <div class="col d-flex">
                <div class="m-l-10">
                  <a id="" aria-expanded="false" class="nav-link" data-bs-toggle="dropdown" role="button"
                  >
                    <img class="account-icon" src="../../assets/images/account.svg">
                  </a>
                </div>
                <div class="m-l-10">
                  <a class="nav-link py-0" href="javascript: void(0)">{{
                      $t("common.userMenu.labelFullName", {
                        lastName: lastName,
                        firstName: firstName
                      })
                    }}</a>
                  <a class="nav-link py-0" href="javascript: void(0)">{{ userId }}</a>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="navbar-nav navbar-mainmenu me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="{ name: 'Home' }" class="nav-link">{{ $t("common.header.home") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'InputHealthCondition' }" class="nav-link">
              {{ $t("common.header.InputHealthCondition") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Record' }" class="nav-link">{{ $t("common.header.record") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Qr' }" class="nav-link">{{ $t("common.header.qr") }}</router-link>
          </li>
        </ul>
        <ul class="navbar-nav mr-auto large-screen">
          <li class="nav-item last-child dropdown">
            <a id="navbarDropdownMenuLink" aria-expanded="false" class="nav-link skippable" data-bs-toggle="dropdown"
               href="#" role="button">
              <img class="account-icon" src="../../assets/images/account.svg">
            </a>
            <ul aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu custom-dropdown-menu">
              <li><a class="dropdown-item no-link" href="javascript: void(0)">{{
                  $t("common.userMenu.labelFullName", {
                    lastName: lastName,
                    firstName: firstName
                  })
                }}</a>
              </li>
              <li><a class="dropdown-item no-link" href="javascript: void(0)">{{ userId }}</a></li>
              <li class="border-top">
                <router-link :to="{ name: 'AccountSettings' }" class="dropdown-item">
                  {{ $t("common.userMenu.accountSettings") }}
                </router-link>
              </li>
              <li><a class="dropdown-item" v-on:click="doLogout">{{ $t("common.userMenu.logOut") }}</a></li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav mr-auto custom-small-screen">
          <div class="row">
            <li>
              <router-link class="nav-link" to="/account">{{ $t("common.userMenu.accountSettings") }}</router-link>
            </li>
            <li><a class="nav-link cursor-pointer" v-on:click="doLogout">{{ $t("common.userMenu.logOut") }}</a></li>
          </div>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import Common from "../../assets/jsipclient/common"

export default {
  name: "NavBar",
  data() {
    return {
      userId: '',
      response: {},
      loading: true,
      logoutError: false,
      lastName: '',
      firstName: '',
      metaTitle: ''
    }
  },

  computed: {
    ...mapGetters(['config'])
  },
  watch: {
    '$route'(to, from) {
      this.setTitle()
      if (to.name === "Login") {
        this.lastName = ''
        this.firstName = ''
        this.userId = ''
      } else if (from.name === "EditName") {
        // force Update
        this.getUser()
      } else if (this.userId === '' || this.userId === null) {
        this.getUser()
      }
    }
  },
  methods: {
    ...mapActions(['logout']),
    setTitle() {
      var setTitle = this.$t('app.default.brandName')

      if (this.$route.name) {
        this.metaTitle = this.$t('meta.title.' + this.$route.name)
        setTitle = setTitle + ' - ' + this.metaTitle
      }
      document.title = setTitle
    },
    async getUser() {
      const user_id = this.$store.getters.config.userId
      const token = this.$store.getters.config.token
      if (user_id !== null && token !== null) {
        try {
          const api = Common.createUserApi(token)
          const jsonObject = await api.usersUserIdGetAsync(user_id)
          if (jsonObject.requireUserInfo === false) {
            this.userId = jsonObject.userId
            this.lastName = jsonObject.lastName
            this.firstName = jsonObject.firstName
          }
        } catch (error) {
          const errorResult = error.response.data
        }
      }
    },
    async doLogout() {
      this.logout()
          .then((result) => {
            this.lastName = ''
            this.firstName = ''
            this.userId = ''

            this.$router.push({name: 'Login'})
          })
          .catch((error) => {
            // 何もしない
          })
    }
  },
  mounted: function () {
    this.getUser()
    this.userId = this.$store.getters.config.userId
    this.setTitle()

    $(document).click(function (event) {
      var $navbar = $(".navbar-collapse");
      var isShowNavBar = $navbar.hasClass('show');
      if (isShowNavBar) {
        $navbar.collapse('hide');
      }
    });
  }
}
</script>

<style lang="scss" scoped>

.logo-title {
  font-weight: bold;
  color: #002947;
  font-size: smaller;
}

.account-icon {
  height: 32px;
  width: 32px;
}

#navbarSupportedContent {
  padding-left: 12px;
}

ul li {
  font-weight: bold;
  color: #002947;
}

.custom-dropdown-menu {
  //margin: -15px;
  //background-color: #F8F9FA;
  //border: none;
  //left: -86px !important;
  background-color: #FFFFFF;
  border: 1px solid #DACED1;

  .dropdown-item {
    font-weight: bold;
    color: #333333;
    cursor: pointer;
    padding-right: 16px;
  }

  .no-link {
    font-weight: bold;
    color: #888888;
    //padding-right: 0;
  }

  .dropdown-item:hover {
    background-color: #EFF2F7;
  }

  @media (min-width: 992px) {
    position: fixed !important;
    top: 43px;
    right: 20px;
    left: auto;
    //border-top-left-radius: 0;
    //border-top-right-radius: 0;
    &.show {
      display: inline-block;
    }
  }
}

a.router-link-active.router-link-exact-active.nav-link {
  color: #3490dc;
}

a.router-link-active.router-link-exact-active.dropdown-item {
  color: #3490dc;
}

.navbar {
  background-color: #FFFFFF;
  padding: 0 !important;
}

img {
  width: 58px;
  height: auto;
}

.small-screen .row {
  margin-left: -24px;
  border-bottom: 1px solid #D3D8EA;
  margin-right: 0;
  padding: 10px 0;
}

.small-screen .row .col-2 > a {
  padding-left: 10px;
}

.custom-small-screen {
  margin-left: -24px;
  border-top: 1px solid #D3D8EA;
  margin-right: 0;
  padding: 10px 24px;
}

.large-screen {
  display: none;
}

#navbarSupportedContent {
  padding-left: 25px;
  border-top: 1px solid #D3D8EA;
  margin-left: -13px;
  margin-right: -12px;
  @media (max-width: 991px) {
    margin-top: 10px;
  }
}

.navbar {
  border-bottom: 2px solid #D3D8EA;

  &.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99999;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .navbar {
    padding: 5.36px 20px !important;
  }

  .small-screen, .custom-small-screen {
    display: none;
  }

  .large-screen {
    display: block;
  }

  #navbarSupportedContent {
    padding-left: 115px;
  }

  .container-fluid {
    border-bottom: none;
  }

  #navbarSupportedContent {
    border-top: none;
  }

}


.nav {
  &bar {
    &-toggler {
      &, &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    &-brand {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 0 !important;

      img {
        width: auto !important;
        height: 44px !important;
      }
    }

    .logo-title {
      padding-top: 0;
      font-size: 10px;
      line-height: 13px;
      position: absolute;
      left: 72px;
      width: 125px;
      @media (max-width: 991px) {
        position: absolute;
        top: 16px;
        left: 74px;
      }
    }

    @media (max-width: 991px) {
      border-bottom-width: 2px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      padding-left: 10px !important;
      .container-fluid {
        padding-left: 0px !important;
      }
      &-brand {
        padding-left: 11px !important;
      }
    }

    &-nav.custom-small-screen {
      @media (max-width: 991px) {
        padding-top: 10px;
        padding-bottom: 0px;
        .nav-link {
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 1;
        }
      }
    }

    &-mainmenu {
      @media (max-width: 991px) {
        padding-top: 10px;
        padding-bottom: 10px;
        .nav-link {
          padding-top: 10px;
          padding-bottom: 10px;
          line-height: 1;
        }
      }
      @media (min-width: 992px) {
        margin-left: 20px;
        li {
          a {
            padding-right: 24px !important;
          }

          &:last-child a {
            padding-right: 0 !important;
          }
        }
      }

    }
  }

  &-item {

  }

  &-link {
    &.skippable {
      padding-right: 0 !important;
    }
  }
}

// Top Navigation bar:END
</style>
