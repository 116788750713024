<template>
  <footer>
    <div class="copyright">
      <div class="container-fluid">
        <div class="row footer-div">
          <div class="col-12 footer-div-col">
            <div class="col-xl-7 col-lg-7">
              <div class="row col footer-menu font-12">
                <div class="col mt-2 footer-item">
                  <router-link :to="{ name: 'PrivacyPolicy' }">{{ $t("common.footer.privacy") }}</router-link>
                </div>
                <div :class="locale === 'en'? 'text-xl-first text-lg-first text-md-first text-sm-first text-xs-center'
                : 'text-xl-center text-lg-center text-md-center text-sm-center text-xs-first'"
                     class="col-3 mt-2 footer-item">
                  <router-link :to="{ name: 'TermsOfService' }">{{ $t("common.footer.terms") }}</router-link>
                </div>
                <div class="col mt-2 footer-item">
                  <router-link :to="{ name: 'CookiePolicy' }">{{ $t("common.footer.cookie") }}</router-link>
                </div>
                <div class="col w-en-100 mt-2 footer-item">
                  <router-link :to="{ name: 'Credit' }">{{ $t("common.footer.credit") }}</router-link>
                </div>
                <div class="col mt-2 footer-item">
                  <router-link :to="{ name: 'Contact' }">{{ $t("common.footer.contact") }}</router-link>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="col-12 footer-copyright">
                <div class="col-xs-6">
                  <div class="locale-switcher">
                    <i class="fas fa-globe0">
                      <country-flag :country='flag' size='small'/>
                    </i>
                    <select v-model="locale" :value="$i18n.locale" class="localize-dropdown ml-2"
                            @change.prevent="changeLocale">
                      <option v-for="locale in locales" :key="locale.code" :value="locale.code">
                        <country-flag :country='flag' size='small'/>
                        {{ locale.name }}
                      </option>
                    </select>
                    <i aria-hidden="true" class="fa fa-sort sort-arrow"></i>
                  </div>
                </div>
                <div class="col-xs-6">
                  <p class="copyright-text font-11">{{ $t("common.footer.copyright") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {getSupportedLocales} from '@/util/i18n/supported-locales'
import CountryFlag from 'vue-country-flag'
import Helper from '@/assets/jsipclient/helper'
import {defaults} from '@/constants'

export default {
  name: "Footer",
  components: {
    CountryFlag
  },
  data: () => ({
    flag: 'jp',
    locale: 'ja',
    metaTitle: '',
    locales: getSupportedLocales()
  }),
  created() {
    this.initialPageLoadHandler()
  },
  methods: {
    initialPageLoadHandler() {
      this.setDefaultLocale()
    },
    async setDefaultLocale() {
      const locale = await Helper.getDefaultLocale()
      this.locale = locale
      this.initializeLocaleSettings(locale)
    },
    initializeLocaleSettings(locale) {
      this.flag = locale === 'ja' ? 'jp' : 'us'
      this.setLocale(locale)
      this.setMetaTitle()
      this.setLocaleCss(locale)
      moment.locale(locale)
    },
    changeLocale(e) {
      const locale = e.target.value
      this.locale = locale
      this.initializeLocaleSettings(locale)
      Helper.cookie.set(defaults.alias.languageKey, locale, 400)
      this.reloadPage()
    },
    reloadPage() {
      const router = this.$router
      const to = router.currentRoute
      const fallbackRoute = '/t?' + moment.now()
      router.replace(fallbackRoute)
      router.replace(to)
    },
    setLocale(locale) {
      this.$i18n.locale = locale
    },
    setLocaleCss(locale) {
      $("#app").removeClass('en ja').addClass(locale)
    },
    setMetaTitle() {
      var setTitle = this.$t('app.default.brandName')

      if (this.$route.name) {
        this.metaTitle = this.$t('meta.title.' + this.$route.name)
        setTitle = setTitle + ' - ' + this.metaTitle
      }
      document.title = setTitle
    }
  }
}
</script>

<style lang="scss" scoped>

.footer-div {
  display: flex;
}

.footer-div-col {
  display: inherit;
}

.localize-dropdown {
  margin-left: 5px;
  padding: 0.275rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}

a {
  text-decoration: none;
}

footer {
  padding-top: 17px;
  padding-bottom: 17px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #888888;
  z-index: 1;
  background-color: #FFFFFF;
}

.footer-menu {
  max-width: 532px;
}

.footer-menu, .footer-menu .col-xs-6 {
  display: flex;
  padding-right: 20px;
}

.footer-menu .col-xs-6 .col-xs-3:first-child {
  padding-left: 0;
}

.footer-menu .col-xs-6 .col-xs-3:last-child {
  padding-right: 0;
}

.footer-menu .col-xs-6 .col-xs-3 {
  padding-left: 10px;
  padding-right: 10px;
}

.footer-copyright .col-xs-6 {
  float: right !important;
}

.footer-copyright .col-xs-6:last-child {
  padding-right: 30px;
}

.footer-menu .col-xs-6:first-child {
  padding-top: 8px;
}

.footer-menu .col-xs-6:last-child {
  padding-top: 8px;
}

.copyright span, .copyright a {
  color: #878787;

  &:hover {
    color: #2663D1;
    text-decoration: none;
  }
}

.copyright-text {
  padding-top: 8px;
}

.sort-arrow {
  left: 78px !important;
}

@media only screen and (max-width: 991px) {

  .footer-menu {
    padding-right: 0;
  }

  .footer-div-col {
    display: initial;
  }

  .footer-copyright .col-xs-6 {
    float: initial !important
  }

  .footer-copyright .col-xs-6:first-child {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .footer-copyright .col-xs-6:last-child {
    padding-top: 7px;
  }

}

@media only screen and (max-width: 768px) {

  .footer-menu {
    padding-right: 0;
  }

  .footer-menu .col-xs-6 {
    display: block;
  }

  .footer-menu .col-xs-6:first-child {
    padding-right: 38px;
    padding-top: 0;
  }

  .footer-menu .col-xs-6:last-child {
    padding-left: 38px;
    padding-top: 0;
  }

  .footer-menu .col-xs-6:first-child .col-xs-3, .footer-menu .col-xs-6:last-child .col-xs-3 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .footer-menu .col-xs-6 .col-xs-3 {
    padding-left: 0;
    padding-right: 0;
  }

}

@media only screen and (max-width: 576px) {
  .footer-item {
    width: 50%;
    flex: 0 0 auto;
  }
}

@media only screen and (max-width: 376px) {

  .copyright-menu ul {
    margin: 0;
    font-size: 0.8rem;
    text-align: left;
  }

  footer {
    padding: 6px 0;
  }

  select .localize-dropdown {
    margin-left: -17px;
    margin-top: -23px;
  }

  select {
    width: 74px;
  }

}

footer {
  border-top: 1px solid #D3D8EA;

  .locale-switcher {
    position: relative;
    top: 6px;
    left: -6px;

    i.fas, i.fa {
      position: absolute;
      left: 15px;
      top: 9px;
    }

    select {
      width: 90px;
      padding: 5px 0 5px 28px;
      font-size: 12px;
      color: #888888;

      &, &:focus {
        outline: none;
      }
    }

  }

  .localize-dropdown {
    option {
      &:before {
        content: "\f0ac";
      }

      &:after {
        content: "&#xf0dc;";
      }
    }
  }
}

.copyright {
  padding-left: 0px;

  &-text {
    margin-bottom: 0;
  }
}

/*Large devices (desktops, 992px and up)*/
@media only screen and (min-width: 992px) {
  .copyright {
    padding-left: 18px;
  }
}

</style>